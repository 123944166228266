/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */

// Serviço de exemplo/template para realizar requisições

import { ApiService } from "./index";

class ImageService {
  setChannelImageSrc(uniqueId) {
    if (uniqueId != null && uniqueId != undefined && uniqueId != "") {
      return ApiService.get(`/app/v4/canal/image/${uniqueId}`).then(
        (response) => response.data
      );
    }
  }

  getUserImage(userId) {
    if (userId != null && userId != undefined && userId != "") {
      return ApiService.get(
        `/app/v1/user/${userId}/picture/size/Small/desk`
      ).then((data) => data.data);
    }
  }

  newGetUserImage(uniqId) {
    return ApiService.get(`/app/v1/user/picture/${uniqId}`).then(
      (data) => data.data
    );
  }

  getImageByUniqId(id_image) {
    return ApiService.get(
      `/app/v4/canal/tickets/image/${id_image}`
    ).then((data) => data.data);
  }
}

export default new ImageService();
