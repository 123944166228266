
<template>
<!-- herder do componente de post -->
  <div class="post-container"   style=" word-wrap: break-word;">
    <div class="first-row">
      <div class="left-info">
        <div id="nav-user">
          <svg
            width="34"
            height="34"
            viewBox="0 0 24 24"
            class="right-button-icon"
            v-if="this.creatorImage == undefined || creatorImage == ''"
          >
            <path
              d="M17.754 14a2.249 2.249 0 0 1 2.249 2.25v.918a2.75 2.75 0 0 1-.513 1.598c-1.545 2.164-4.07 3.235-7.49 3.235c-3.421 0-5.944-1.072-7.486-3.236a2.75 2.75 0 0 1-.51-1.596v-.92A2.249 2.249 0 0 1 6.251 14h11.502ZM12 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10Z"
            />
          </svg>
          <img
            :src="'data:image/png;base64,' + this.creatorImage"
            width="36px"
            height="36px"
            class="creatorProfilePic"
            @error="creatorImageError"
            v-else
          />
        </div>
        <div id="info">
          <p class="user-name">{{ fullName }} </p>
          <p class="days-count">{{ days }}</p>
        </div>
      </div>
      <!-- herder do componente de post -->

      <!-- EDIT POST -->
      <div class="edit-div" v-if="redactorId == loggedUser.id">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          class="more-opts"
          @click="openPostOpts()"
        >
          <path
            fill="#808080"
            d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2z"
          />
        </svg>
        <div class="editPost-opts" v-if="displayOptsEditPost">
          <div class="editPost-text-holder">
                <p class="editPost-opts-text">Editar post</p>
            </div>
           <div
            class="editPost-text-holder"
            @click="deletePost(this.editorialId, this.feedId)"
          >
            <p class="editPost-opts-text">Excluir post</p>
          </div>
        </div>
      </div>
    </div>
     
    <!-- legenda do componente post  -->
    <div class="text-holder" style="object-fit: cover; " v-if="postText != ''" >
      <ReadMore :longText="postText" v-if="postText.length > 192" />
      <p v-else v-html="linkify(postText)"></p>
    </div>
    <!-- legenda do componente post -->
     
    <!-- midia do componente post-->
    <div class="second-row" >
      <div
        class="image-post"
        v-if="postImage.length != 0 || postVideo.length != 0"
      >
        <PostImage :contentImages="postImage" :contentVideo="postVideo" />
      </div>
    </div>
    <!-- midia do componente post -->

    <!--  reação e comentarios do componente post -->
    <div id="post-info" >
      <div class="reactions-info" v-if="reactions != 0">
        <img
          :src="setEmpilhadeiraSrc(1)"
          class="empilhadeira-icon"
          style="z-index: 3"
          v-if="this.empilhadeira.length >= 1"
        />
        <img
          :src="setEmpilhadeiraSrc(2)"
          class="empilhadeira-icon"
          style="z-index: 2"
          v-if="this.empilhadeira.length >= 2"
        />
        <img
          :src="setEmpilhadeiraSrc(3)"
          class="empilhadeira-icon"
          style="z-index: 1"
          v-if="this.empilhadeira.length == 3"
        />

        <p class="post-info-text" style="margin-top: 12px">{{ reactions }}</p>
      </div>

        <!-- botao de reação do componente post -->
      <div class="reactions-balloon" >
        <div class="innerReactionsBallon">
          <div
            v-for="(reaction, i) in allReactions"
            :key="i"
            class="reactionDivImgMini"
          >
            <div v-if="i == 0">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["like"] }}</p>
            </div>

            <div v-if="i == 1">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["love"] }}</p>
            </div>

            <div v-if="i == 2">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["haha"] }}</p>
            </div>

            <div v-if="i == 3">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["wow"] }}</p>
            </div>

            <div v-if="i == 4">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["sad"] }}</p>
            </div>

            <div v-if="i == 5">
              <img
                :src="reaction.image"
                v-if="i != 6 && i != 7"
                class="miniImgReactions"
              />
              <p class="reactionText">{{ this.typesReactions["grr"] }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- botao de reação do componente post -->
       
       <!-- vizualicao do comentarios do componente post  -->
      <p class="post-info-text" @click="handleComment" v-if="dataComments != 0" >
        {{ dataComments }}
        <span v-if="dataComments != 1">{{ $t("comments") }}</span
        ><span v-else>{{ $t("comment") }}</span>
      </p>

      <p class="post-info-text-no" v-else>
        {{ dataComments }}
        <span v-if="dataComments != 1">{{ $t("comments") }}</span
        ><span v-else>{{ $t("comment") }}</span>
      </p>
    </div>
    <div
      :class="
        this.createCommentsPermission == 1 ? 'third-row' : 'third-row-NoBorder'
      "
    >
      <div
        :class="
          this.createReactionsPermission == 1
            ? 'item-div'
            : 'item-div-NoDisplay'
        "
        id="likeButton"
        @click="reactPost(this.feedId, 1)"
        v-if="this.userReaction == 0"
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 24 24"
          class="button-icon"
          v-if="this.userReaction == 0"
        >
          <path
            d="M16.5 5.203c0-2.442-1.14-4.2-3.007-4.2c-1.026 0-1.378.602-1.746 2c-.075.29-.112.43-.151.569c-.101.358-.277.97-.527 1.83a.25.25 0 0 1-.03.065L8.174 9.953a5.885 5.885 0 0 1-2.855 2.327l-.473.181a2.75 2.75 0 0 0-1.716 3.092l.404 2.086a3.25 3.25 0 0 0 2.417 2.538l7.628 1.87a4.75 4.75 0 0 0 5.733-3.44l1.415-5.55a3.25 3.25 0 0 0-3.15-4.053h-1.822c.496-1.633.746-2.892.746-3.801ZM4.6 15.267a1.25 1.25 0 0 1 .78-1.405l.474-.181a7.384 7.384 0 0 0 3.582-2.92l2.867-4.486c.09-.14.159-.294.205-.454c.252-.865.428-1.48.53-1.843a24.7 24.7 0 0 0 .159-.593c.19-.722.283-.881.295-.881c.868 0 1.507.984 1.507 2.699c0 .884-.326 2.335-.984 4.315a.75.75 0 0 0 .711.986h2.85a1.75 1.75 0 0 1 1.696 2.182l-1.415 5.55a3.25 3.25 0 0 1-3.923 2.353l-7.628-1.87a1.75 1.75 0 0 1-1.301-1.366L4.6 15.267Z"
          />
        </svg>
        <img
          :src="setSrcReaction(this.userReaction)"
          v-if="this.userReaction != 0"
          class="userReactionIcon"
        />
        <p class="item-text">{{ $t("likeVerb") }}</p>
      </div>

      <div
        :class="
          this.createReactionsPermission == 1
            ? 'item-div'
            : 'item-div-NoDisplay'
        "
        id="likeButton"
        @click="reactPost(this.feedId, 0)"
        v-if="this.userReaction != 0"
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 24 24"
          class="button-icon"
          v-if="this.userReaction == 0"
        >
          <path
            d="M16.5 5.203c0-2.442-1.14-4.2-3.007-4.2c-1.026 0-1.378.602-1.746 2c-.075.29-.112.43-.151.569c-.101.358-.277.97-.527 1.83a.25.25 0 0 1-.03.065L8.174 9.953a5.885 5.885 0 0 1-2.855 2.327l-.473.181a2.75 2.75 0 0 0-1.716 3.092l.404 2.086a3.25 3.25 0 0 0 2.417 2.538l7.628 1.87a4.75 4.75 0 0 0 5.733-3.44l1.415-5.55a3.25 3.25 0 0 0-3.15-4.053h-1.822c.496-1.633.746-2.892.746-3.801ZM4.6 15.267a1.25 1.25 0 0 1 .78-1.405l.474-.181a7.384 7.384 0 0 0 3.582-2.92l2.867-4.486c.09-.14.159-.294.205-.454c.252-.865.428-1.48.53-1.843a24.7 24.7 0 0 0 .159-.593c.19-.722.283-.881.295-.881c.868 0 1.507.984 1.507 2.699c0 .884-.326 2.335-.984 4.315a.75.75 0 0 0 .711.986h2.85a1.75 1.75 0 0 1 1.696 2.182l-1.415 5.55a3.25 3.25 0 0 1-3.923 2.353l-7.628-1.87a1.75 1.75 0 0 1-1.301-1.366L4.6 15.267Z"
          />
        </svg>
        <img
          :src="setSrcReaction(this.userReaction)"
          v-if="this.userReaction != 0"
          class="userReactionIcon"
        />
        <p class="item-text">{{ $t("likeVerb") }}</p>
      </div>

      <div class="reactionsHolder">
        <div
          v-for="(reaction, i) in filteredReactions"
          :key="i"
          class="reactionDivImg"
          @click="reactPost(this.feedId, i + 1)"
        >
          <img
            :src="reaction.image"
            v-if="reaction.name != 'busy' && reaction.name != 'attending'"
            class="ReactionImgAnimation"
          />
        </div>
      </div>

      <div
        class="item-div"
        id="commentButton"
        @click="handleComment"
        v-if="dataComments != 0"
      >
        <svg width="32" height="32" viewBox="0 0 24 24" class="button-icon">
          <path
            d="M5.25 18A3.25 3.25 0 0 1 2 14.75v-8.5A3.25 3.25 0 0 1 5.25 3h13.5A3.25 3.25 0 0 1 22 6.25v8.5A3.25 3.25 0 0 1 18.75 18h-5.738L8 21.75a1.25 1.25 0 0 1-1.999-1V18h-.75Zm7.264-1.5h6.236a1.75 1.75 0 0 0 1.75-1.75v-8.5a1.75 1.75 0 0 0-1.75-1.75H5.25A1.75 1.75 0 0 0 3.5 6.25v8.5c0 .966.784 1.75 1.75 1.75h2.249v3.75l5.015-3.75Z"
          />
        </svg>
        <p class="item-text" style="margin-left: 8px">
          {{ $t("commentsUpper") }}
        </p>
      </div>

      <!-- <div
        class="item-div"
        @click="denouncePost(this.editorialId, this.feedId)"
        v-if="redactorId != loggedUser.id"
      >
        <svg width="32" height="32" viewBox="0 0 24 24" class="button-icon">
          <path
            d="M13.25 14.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0ZM11.5 6.75v5a.75.75 0 0 0 1.5 0v-5a.75.75 0 0 0-1.5 0ZM4 4.5A2.5 2.5 0 0 1 6.5 2H18a2.5 2.5 0 0 1 2.5 2.5v14.25a.75.75 0 0 1-.75.75H5.5a1 1 0 0 0 1 1h13.25a.75.75 0 0 1 0 1.5H6.5A2.5 2.5 0 0 1 4 19.5v-15ZM19 18V4.5a1 1 0 0 0-1-1H6.5a1 1 0 0 0-1 1V18H19Z"
          />
        </svg>
        <p class="item-text" style="margin-left: 8px">
          {{ $t("denounceVerb") }}
        </p>
      </div> -->
    </div>

    <div class="fourth-rowComments" v-if="this.createCommentsPermission == 1">
      <div v-for="(comment, i) in this.sessionCreatedComments" :key="i">
        <div class="pseudoInputOwnComment">
          <div id="nav-userComment" class="pseudoInputUserImage">
            <svg
              width="34"
              height="34"
              viewBox="0 0 24 24"
              class="right-button-icon"
              v-if="userImage == undefined || userImage == ''"
            >
              <path
                d="M17.754 14a2.249 2.249 0 0 1 2.249 2.25v.918a2.75 2.75 0 0 1-.513 1.598c-1.545 2.164-4.07 3.235-7.49 3.235c-3.421 0-5.944-1.072-7.486-3.236a2.75 2.75 0 0 1-.51-1.596v-.92A2.249 2.249 0 0 1 6.251 14h11.502ZM12 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10Z"
              />
            </svg>
            <img :src="userImage" class="userImage" v-else />
          </div>
          <div class="textOwnInputComments">
            <p class="userNameTextOwninput">{{ loggedUser.nome }}</p>
            <p class="commentTextOwnInput">{{ comment }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="fourth-row" v-if="this.createCommentsPermission == 1">
      <div id="nav-user">
        <svg
          width="34"
          height="34"
          viewBox="0 0 24 24"
          class="right-button-icon"
          v-if="userImage == undefined || userImage == ''"
        >
          <path
            d="M17.754 14a2.249 2.249 0 0 1 2.249 2.25v.918a2.75 2.75 0 0 1-.513 1.598c-1.545 2.164-4.07 3.235-7.49 3.235c-3.421 0-5.944-1.072-7.486-3.236a2.75 2.75 0 0 1-.51-1.596v-.92A2.249 2.249 0 0 1 6.251 14h11.502ZM12 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10Z"
          />
        </svg>
        <img :src="userImage" class="userImage" v-else />
      </div>
      <input
        type="text"
        :placeholder="$t('typeComment')"
        id="input-text"
        v-model="userCommentPost"
      />
      <div class="sendMsgBtn">
        <div class="svgSend" id="svgSend" @click="createComment">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            class="sendSvgImg"
          >
            <path
              fill="#ffff"
              fill-rule="evenodd"
              d="M2.345 2.245a1 1 0 0 1 1.102-.14l18 9a1 1 0 0 1 0 1.79l-18 9a1 1 0 0 1-1.396-1.211L4.613 13H10a1 1 0 1 0 0-2H4.613L2.05 3.316a1 1 0 0 1 .294-1.071z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageService from "@/services/image.service";
import ReadMore from "@/components/Readmore.vue";
import PostImage from "./PostImageDisplay.vue";
import EditoriaService from "@/services/editoria.service";
import FeedService from "@/services/feed.service";
import { toast } from "vue3-toastify";


export default {
  name: "Post",

  components: {
    PostImage,
    ReadMore,
  },

  props: {
    days: "",
    fullName: "",
    comments: "",
    postImage: "",
    postVideo: "",
    userImage: "",
    creatorImage: "",
    redactorId: "",
    loggedUser: "",
    postText: "",
    allReactions: "",
    filteredReactions: "",
    editorialId: "",
    feedId: "",
    editorias: "",
    createCommentsPermission: "",
    createReactionsPermission: "",
    post: "",
  },

  data() {
    return {
      reactions: "",
      firstLoad: true,
      userReaction: "",
      typesReactions: "",
      userCommentPost: [],
      commentsData: [],
      sessionCreatedComments: [],
      displayOptsEditPost: false,
      imagemDonoPost: "",
      empilhadeira: [],
      dataComments: 0,
      myId: "",
    };
  },

  beforeMount() {
    setTimeout(() => {
      this.dataComments = this.comments;
      this.getReacts(this.editorialId, this.feedId);
      this.getCommentsFeed(this.editorialId, this.feedId);
     
      this.$emit("loaded");
    }, 400);
  },

  methods: {
     linkify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
      });
    },
    setEmpilhadeiraSrc(index) {
      var empilhadeiraOpts = {
        1: this.empilhadeira?.[0]?.type,
        2: this.empilhadeira?.[1]?.type,
        3: this.empilhadeira?.[2]?.type,
      };

      const selectedIndex = empilhadeiraOpts[index];

      var reactionImages = {
        like: "/assets/reactions/like.svg",
        love: "/assets/reactions/love.svg",
        haha: "/assets/reactions/haha.svg",
        wow: "/assets/reactions/uau.svg",
        sad: "/assets/reactions/sad.svg",
        grr: "/assets/reactions/genial.svg",
      };

      return reactionImages[selectedIndex];
    },

    setSrcReaction(userReaction) {
      this.userReaction = userReaction;

      var reactionImages = {
        1: "/assets/reactions/like.svg",
        2: "/assets/reactions/love.svg",
        3: "/assets/reactions/haha.svg",
        4: "/assets/reactions/uau.svg",
        5: "/assets/reactions/sad.svg",
        6: "/assets/reactions/genial.svg",
      };

      return reactionImages[userReaction];
    },

    creatorImageError(e) {
      e.target.width = "36px";
      e.target.src = "/assets/profileHolderBg.svg";
    },

    deletePost(editorialId, feedId) {
      EditoriaService.deletePost(editorialId, feedId).then((response) => {
        if (!response.error) {
          this.displayOptsEditPost = false;
          toast.success(this.$t("actions.successPostDelete"));
          this.$emit("getFeed", this.editorialId);
          this.$forceUpdate();
        }
      });
    },

    getCommentsFeed(editorialId, feedId) {
      FeedService.getComments(editorialId, feedId).then((data) => {
        this.commentsData = data.data;
      });
    },

    handleComment() {
      this.$router.push(`/post/${this.editorialId}/${this.feedId}`);
    },

    denouncePost(editorialId, feedId) {
      EditoriaService.denoucePost(editorialId, feedId).then((response) => {
        if (!response.error) {
          this.$emit("getFeed", this.editorialId);
        }
      });
    },

    openPostOpts() {
      this.displayOptsEditPost = !this.displayOptsEditPost;
    },

    reactPost(feedId, reactCode) {
      if (this.userReaction != reactCode) {
        this.$emit("reactPost", feedId, reactCode);
        this.userReaction = reactCode;
        this.setSrcReaction(this.userReaction);
        return;
      }

      this.$emit("reactPost", feedId, 0);
      this.userReaction = 0;
      reactCode = 0;
      this.setSrcReaction(this.userReaction);
    },

    getReacts(editorialId, feedId) {
      EditoriaService.getReactionsByPost(editorialId, feedId).then((data) => {
        if (!data.error) {
          if (this.firstLoad) {
            this.userReaction = data.user_reaction;
          }
          this.firstLoad = false;
          this.reactions = data.total_reactions;
          this.typesReactions = data.reactions;

          var targetArray = Object.values(this.typesReactions);

          targetArray.splice(6);

          // Array de objetos das reações
          var objetos = [
            {
              type: "like",
              valor: targetArray[0],
            },
            {
              type: "love",
              valor: targetArray[1],
            },
            {
              type: "haha",
              valor: targetArray[2],
            },
            {
              type: "wow",
              valor: targetArray[3],
            },
            {
              type: "sad",
              valor: targetArray[4],
            },
            {
              type: "grr",
              valor: targetArray[5],
            },
          ];

          // Classificar o array em ordem decrescente com base na propriedade "valor"
          objetos.sort(function (a, b) {
            return b.valor - a.valor;
          });

          const filteredArray = objetos.filter((obj) => obj.valor != 0);

          // Obter os três primeiros objetos
          var maiores = filteredArray.slice(0, 3);

          // Setando data no componente
          this.empilhadeira = maiores;
        }
      });

      this.$forceUpdate();
    },

    // setUserImage(userId) {
    //   ImageService.getUserImage(userId).then((data) => {
    //     this.imagemDonoPost = "data:image/png;base64," + data;
    //   });

    //   this.$forceUpdate();
    // },

    createComment() {
      FeedService.commentPost(
        this.editorialId,
        this.feedId,
        this.userCommentPost
      ).then((response) => {
        if (!response.error) {
          this.sessionCreatedComments.push(this.userCommentPost);
          toast.success(this.$t("actions.successComment"));
          this.dataComments += 1;
        }
        this.userCommentPost = "";
      });
    },
  },
};
</script>

<style scoped>
.empilhadeira-icon {
  width: 24px;
  margin-right: -7px;
  margin-top: -4px;
}

.innerReactionsBallon {
  display: flex;
  margin: auto;
  margin-left: 18px;
}

.editPost-opts-text {
  cursor: pointer;
  margin: auto;
  margin-left: 10px;
}

.editPost-text-holder {
  width: 100%;
  margin: auto;
  height: 32px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.editPost-text-holder:hover {
  color: white;
  background: var(--yellow);
  transition: 0.2s;
}

.editPost-opts {
  position: absolute;
  top: 52px;
  right: 25px;
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  border-radius: 5px;
  width: 220px;
  z-index: 12;
}

.more-opts {
  cursor: pointer;
}

.sendMsgBtn {
  position: absolute;
  right: 18px;
}

.userNameTextOwninput {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
}

.textOwnInputComments {
  margin-left: 50px;
  overflow-wrap: anywhere;
  margin-bottom: -18px;
}

.pseudoInputUserImage {
  position: absolute;
  left: 0;
}

.pseudoInputOwnComment {
  background-color: var(--background-grey);
  border: none;
  border-radius: 100px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 58px;
  width: 97%;
  padding: 5px 20px;
  margin-left: 9px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.svgSend {
  background: var(--yellow);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.ReactionImgAnimation {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.ReactionImgAnimation:hover {
  top: -10px;
}

hr {
  width: 95%;
  margin: auto;
  margin-top: 3px;
}

.reactions-icon {
  width: 60px;
  height: 30px;
}

#likeButton:hover + .reactionsHolder {
  visibility: visible !important;
  opacity: 1 !important;
}

.reactionsHolder:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.reactionsHolder {
  z-index: 3;
  height: 50px;
  width: 300px;
  margin-left: 4px;
  margin-top: -68px;
  border-radius: 20px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--background-grey);
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  display: flex;
  transition: visibility 0s, opacity 0.1s linear;
  transition-delay: 0.1s;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.reactionsHolder img {
  width: 42px;
  height: 42px;
  margin: auto;
  cursor: pointer;
}

.reactionDivImg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
}

.reactionDivImgMini {
  align-items: center;
  margin: 5px;
}

.userReactionIcon {
  width: 34px;
  height: 34px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.edit-svg {
  fill: var(--yellow);
}

.userImage {
  border-radius: 100%;
  width: 46px;
  height: 46px;
  object-fit: cover;
}

.creatorProfilePic {
  border-radius: 100%;
  width: 46px;
  height: 46px;
}

.post-info-text {
  font-size: 14px;
  margin-left: 12px;
  cursor: pointer;
}

.post-info-text-no {
  font-size: 14px;
  margin-left: 12px;
}

.right-button-icon {
  fill: var(--darker-grey);
}

.user-name {
  margin: auto;
  margin-left: 7px;
  margin-top: 1px;
  font-size: 16px;
}

.days-count {
  margin: auto;
  font-weight: 500;
  font-size: 14px;
  color: var(--darker-grey);
  margin-left: 7px;
}

.image-post {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.second-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 10px;
  position: relative;
}

.text-holder {
  width: 93%;
  margin: auto;
  margin-top: 10px;
}

.text-holder p {
  margin-bottom: 0;
}

.post-container {
  display: block;
  background-color: var(--white);
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 18px;
  width: 630px;
  border: 2px solid rgba(229, 229, 229, 1);
}


.first-row {
  display: flex;
  align-items: center;
  width: 97%;
  height: 55%;
  margin-right: 10px;
  margin: auto;
  justify-content: space-between;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
 
}

.fourth-row {
  display: flex;
  align-items: center;
  width: 97%;
  height: 55%;
  margin: auto;
  margin-top: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.fourth-rowComments {
  width: 97%;
  height: 55%;
  margin: auto;
  margin-top: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.item-text {

  margin-left: 8px;
  margin-top: 18px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.third-row {
  display: flex;
  width: 95%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  border-top: 1px solid var(--dark-grey);
  border-bottom: 1px solid var(--dark-grey);
  padding-bottom: 53px;
}

.third-row-NoBorder {
  display: flex;
  width: 95%;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  border-top: 1px solid var(--dark-grey);
  margin-bottom: -10px;
}

#input-text {
  background-color: var(--background-grey);
  border: none;
  border-radius: 8px;
  width: 90%;
  height: 40px;
  padding: 5px 15px 5px 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.reactions-info {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.button-icon {
  fill: var(--yellow);
}

#input-text:focus,
#input-text:focus {
  outline: none;
}

.item-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  margin-top: -3px;
  cursor: pointer;
  margin: auto;
}

.item-div-NoDisplay {
  display: none;
}

#nav-user {
  background-color: var(--light-grey);
  border-radius: 100%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

#nav-userComment {
  background-color: var(--light-grey);
  border-radius: 100%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

#info {
  height: 50px;
  margin-left: 2px;
  display: block;
}

#post-info {
  height: 22px;
  width: 94%;
  margin: auto;
  margin-top: 18px;
  margin-bottom: -12px;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-post {
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 16px;
}

.edit-div {
  margin-right: 14px;
  margin-top: -5px;
}

.left-info {
  display: flex;
}

.reactions-balloon {
  width: 300px;
  height: 100px;
  background: var(--background-grey);
  border-radius: 10px;
  position: absolute;
  text-align: center;
  margin-top: -112px;
  margin-left: -15px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.miniImgReactions {
  width: 36px;
  height: 36px;
  margin-top: 8px;
}

.reactionText {
  margin-top: 15px;
}

.reactions-info:hover + .reactions-balloon {
  visibility: visible;
  opacity: 1;
}
</style>
