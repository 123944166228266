<template>
  <div
    class="editorial-item"
  >
    <img
      :src="this.editoriasNew.icon"
      class="editorial-image"
      v-if="this.editoriasNew.icon && this.editoriasNew.icon !== ''"
      @error="returnImageError"
    />
    <p
      class="selected-editorial-title"
      v-if="this.editoriasNew.title && this.editoriasNew.title !== ''"
    >
      {{ this.editoriasNew.title }}
    </p>
  </div>
</template>
<script>
export default {
  components: {},

  beforeUpdate() {},
  beforeMount() {},
  data() {
    return {};
  },

  directives: {},

  emits: ["rendering", "getFeed"],

  props: {
    editoriasNew: "",
  },
  methods: {},
  emits: ["getFeed", "getNewFeeds"],
};
</script>

<style>
.scroll-arrow {
  position: fixed;
  transform: translateY(-50%);
}

.scroll-arrow.left {
  left: 2px;
  cursor: pointer;
}

.scroll-arrow.right {
  right: 0;
  cursor: pointer;
}
.backBtn {
  position: fixed;
  left: 8px;
  top: 25px;
  cursor: pointer;
  transform: rotate(180deg);
}

.nextBtn {
  position: fixed;
  right: 8px;
  top: 25px;
  cursor: pointer;
}

#carousel-editorial {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow-x: auto;
}

#carousel-editorial::-webkit-scrollbar {
  width: 0px;
}

.editorial-item {
  text-align: center;
  width: 100%;
  max-width: 100px;
  height: 105px;
  cursor: pointer;
  margin-right: 15px;
}

.editorial-title {
  color: black;
  font-size: 14px;
  margin-top: 5px;

  width: 11ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-editorial-title {
  color: black;
  font-size: 14px;
  margin-top: 5px;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--yellow);

  width: 11ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editorial-image {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiper-button-next {
  color: var(--yellow);
  margin-top: -40px;
}

.swiper-button-prev {
  color: var(--yellow);
  margin-top: -40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px;
}

#carousel-editorial .swiper {
  padding-left: 14px;
  padding-right: 14px;
}
</style>
