<template>
  <div>
    <swiper
      :slides-per-view="1"
      :space-between="5"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :modules="modules"
      :roundLengths="false"
      :pagination="{
        clickable: true,
      }"
      class="post-swiper"
    >
      <swiper-slide
        class="post-swiper-slide"
        v-for="(content, i) in this.contentsArr"
        :key="i"
      >
        <div class="image-holder" v-if="content?.video == undefined">
          <a :href="content.imagem" target="_blank">
            <img :src=" content.imagem" class="inner-image-holder" />
          </a>
        </div>
        <div class="newImageHolder" v-else>
          <img :src="content.image" class="blurBackground" />
          <video
            autoplay
            muted
            controls
            :poster="content.image"
            class="video-player"
            id="videoPlayer"
            :src="content.video"
          ></video>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

export default {
  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Navigation],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    feedsLength: "",
    editorias: "",
    title: "",
    contentImages: "",
    contentVideo: "",
  },
  emits: ["getFeed"],

  data() {
    return {
      displayfullSizeImg: false,
      contentsArr: [],
    };
  },

  beforeMount() {
    this.mergeArrays();
  },

  methods: {
    mergeArrays() {
      this.contentImages.forEach((element) => {
        this.contentsArr.push(element);
      });

      this.contentVideo.forEach((element) => {
        this.contentsArr.push(element);
      });

      this.$forceUpdate();
    },
  },
};
</script>

<style>
.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inner-image-holder {
  max-height: 520px;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
  background-color: var(--background-grey);
}

.post-swiper {
  display: block;
  padding-left: 0px;
  max-width: 572px;
}

.post-swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin: auto;
}

.swiper-pagination-bullet-active {
  background: var(--yellow);
}

.swiper,
.swiper-initialized,
.swiper-horizontal .swiper-pointer-events,
.swiper-backface-hidden {
  margin-left: 0;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.post-swiper {
  position: inherit;
}

.newImageHolder {
  position: relative;
  width: 100%;
}

.blurBackground {
  z-index: 1;
  width: 100%;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: brightness(0.5) blur(2px);
}

.video-player {
  width: 100% !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}

#videoPlayer:focus,
#videoSrc:focus {
  outline: none;
}

@media only screen and (max-width: 1260px) {
  .post-swiper {
    max-width: 500px;
  }
}
</style>
