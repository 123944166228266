import { ApiService } from "./index";

class CommunicationService {
  // Consumo todos os canais
  getOverAll() {
    return ApiService.get("app/v4/canal").then((data) => data.data);
  }

  // Get canais que sou manager
  getManager() {
    return ApiService.get("/app/v4/canal/manager/get").then(
      (data) => data.data
    );
  }

  // Get canais da minha empresa (user)
  getUser() {
    return ApiService.get(`/app/v4/canal/user/channels`).then(
      (response) => response.data
    );
  }

  // Pegar os meus tickets arquivados por canal
  getMyArquivados(canalId) {
    return ApiService.get(`app/v4/canal/${canalId}/tickets/record/user`).then(
      (data) => data.data
    );
  }

  // Get tickets por canal user
  getChannelByIdUser(channelId) {
    return ApiService.get(`/app/v5/canal/${channelId}/tickets`).then(
      (response) => response.data
    );
  }

  // Get tickets por canal manager
  getChannelByIdManager(channelId) {
    return ApiService.get(`/app/v4/canal/${channelId}/tickets/all/v2`).then(
      (response) => response.data
    );
  }

  // Pegar informação do canal por ID
  getChannelInfo(channelId) {
    return ApiService.get(`app/v4/canal/${channelId}`).then(
      (response) => response.data
    );
  }

  // Get ticket por Id
  getTicketById(channelId, ticketId) {
    return ApiService.get(
      `/app/v4/canal/${channelId}/tickets/${ticketId}`
    ).then((response) => response.data);
  }

  // Message consumers
  getMessagesByTicket(ticketId) {
    return ApiService.get(`/app/v4/canal/tickets/${ticketId}/message`).then(
      (response) => response.data
    );
  }

  // Criar mensagem
  createMessage(canalId, ticketId, data) {
    const formData = new FormData();

    if (
      data.mensagem != null &&
      data.mensagem != "" &&
      data.mensagem != undefined
    ) {
      formData.append("mensagem", data.mensagem);
    }

    if (data.file != null && data.file != "" && data.file != undefined) {
      formData.append("file", data.file);
    }

    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketId}/message/create`,
      formData
    ).then((response) => response.data);
  }

  // Criar novo ticket
  createNewTicket(canalId, data) {
    let formData = new FormData();

    formData.append("assunto", data);

    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/create`,
      formData
    ).then((response) => response.data);
  }

  // Finalizar ticket como manager
  finishTicket(canalId, ticketId) {
    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketId}/closeTicket`
    ).then((response) => response.data);
  }

  // Finalizar ticket como usuário
  closeTicketUser(canalID, ticketId) {
    return ApiService.post(
      `/app/v4/canal/${canalID}/tickets/${ticketId}/closeTicket`
    ).then((response) => response.data);
  }

  // Transferir ticket para outro canal
  transferTicket(canalId, ticketId, data) {
    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketId}/transfer`,
      data
    ).then((response) => response.data);
  }

  // Canais que tem permissão para transferir
  getTransfersChannels(canalId) {
    return ApiService.get(`/app/v4/canal/${canalId}/transfer`).then(
      (data) => data.data
    );
  }

  // Retornar ticket de onde veio
  returnTicket(canalId, ticketUniqId) {
    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketUniqId}/back`
    ).then((response) => response.data);
  }

  // Avaliar ticket
  avaliarTicket(canalId, ticketId, data) {
    // console.log(canalId, ticketId, data);
    const formdata = new FormData();

    formdata.append("avaliacao", data.number);
    formdata.append("comentario_avaliacao", data.text);

    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketId}/rank`,
      formdata
    ).then((response) => response.data);
  }

  // Arquivar ticket
  arquivarTicket(canalId, ticketId) {
    return ApiService.post(
      `/app/v4/canal/${canalId}/tickets/${ticketId}/record`
    ).then((response) => response.data);
  }

  // Pegar configurações do módulo (Nome e status)
  getConfig() {
    return ApiService.get(`/app/v4/canal/configs`).then((data) => data.data);
  }

  // MARKS METHODS
  createMark(canalId, data) {
    const formdata = new FormData();

    formdata.append("nome", data.nome);
    let str = data.cor;
    let newStr = str.substring(1);

    formdata.append("cor", newStr);

    formdata.append("padrao", 0);

    return ApiService.post(`/app/v4/canal/${canalId}/kanban`, formdata).then(
      (data) => data.data
    );
  }

  deleteMark(canalId, marcadorId) {
    return ApiService.post(
      `/app/v4/canal/${canalId}/kanban/${marcadorId}/delete`
    ).then((data) => data.data);
  }

  getMarks(canalId) {
    return ApiService.get(`/app/v4/canal/${canalId}/kanban`).then(
      (data) => data.data
    );
  }

  updateMark(canalId, data) {
    const formdata = new FormData();

    formdata.append("nome", data.nome);

    let str = data.cor;
    let newStr = str.substring(1);

    formdata.append("cor", newStr);

    return ApiService.post(
      `/app/v4/canal/${canalId}/kanban/${data.id}/update`,
      formdata
    ).then((response) => response.data);
  }

  defaultMark(canalId, kanbanId) {
    const formdata = new FormData();

    formdata.append("padrao", 1);

    return ApiService.post(
      `/app/v4/canal/${canalId}/kanban/${kanbanId}/update`,
      formdata
    ).then((response) => response.data);
  }

  changeOrderMarks(canalId, arr) {
    const formdata = new FormData();

    arr.forEach((element, index) => {
      formdata.append(`kanban[${index}][id]`, element.id);
      formdata.append(`kanban[${index}][ordem]`, index);
    });

    return ApiService.post(
      `/app/v4/canal/${canalId}/kanban/order`,
      formdata
    ).then((response) => response.data);
  }

  changeKanban(codigoTicket, kanbanId) {
    const formdata = new FormData();

    formdata.append("id_kanban", kanbanId);

    return ApiService.post(
      `/app/v4/canal/tickets/${codigoTicket}/update`,
      formdata
    ).then((response) => response.data);
  }

  getFile(fileCode) {
    return ApiService.get(
      `/app/v4/canal/tickets/image/${fileCode}`
    ).then((response) => response.data);
  }

  getNotes(ticketId) {
    return ApiService.get(`/app/v4/canal/tickets/${ticketId}/obs`).then(
      (data) => data.data
    );
  }

  postNote(ticketId, data) {
    return ApiService.post(`/app/v4/canal/tickets/${ticketId}/obs`, data).then(
      (response) => response.data
    );
  }

  assumirTicket(codigo_ticket) {
    return ApiService.post(`/app/v4/canal/tickets/${codigo_ticket}/apply/moderator`).then(
      (response) => response.data
    );
  }

  acceptTransfer(codigo_ticket, data) {
    var formdata = new FormData();

    formdata.append("aceito", data.aceito)

    if(data.resposta != null && data.resposta != undefined) {
      formdata.append("resposta", data.resposta)
    }

    return ApiService.post(`/app/v4/canal/tickets/${codigo_ticket}/accept-transfer`, formdata).then(
      (response) => response.data
    );
  }

  // APIS DE APOIO
  
  // RECEBER APOIOS DO MEU CANAL
  getApoios(codigoCanal) {
    return ApiService.get(`app/v4/canal/${codigoCanal}/helper`).then(
      (response) => response.data
    );
  }

  getMessagesApoio(codigoApoio) {
    return ApiService.get(`app/v4/canal/tickets/help/${codigoApoio}/message`).then(
      (response) => response.data
    );
  }

  getApoioById(codigoCanal, codigoTicket) {
    return ApiService.get(`/app/v4/canal/${codigoCanal}/tickets/${codigoTicket}/helper/verify`).then(response => response.data)
  }

  createMessageApoio(canalId, codigoTicket, data) {
    const formData = new FormData();

    if (
      data.mensagem != null &&
      data.mensagem != "" &&
      data.mensagem != undefined
    ) {
      formData.append("mensagem", data.mensagem);
    }

    if (data.file != null && data.file != "" && data.file != undefined) {
      formData.append("file", data.file);
    }

    return ApiService.post(`/app/v4/canal/${canalId}/tickets/${codigoTicket}/help/create/message`, formData).then(response => response.data)
  }
  getUserImage(oldUniqId) {
    return ApiService.get(`/app/v1/user/picture/${oldUniqId}`).then(
      (response) => response.data
    );
  }

  createApoio(codigoTicket, canalApoio) {
    const formData = new FormData();

    formData.append("canal_apoio", canalApoio)

    return ApiService.post(`/app/v4/canal/tickets/${codigoTicket}/help`, formData).then(response => response.data)
  }

}
export default new CommunicationService();
