<template>
  <div id="carousel-editorial" v-dragscroll.x="true">
    <img class="scroll-arrow left" src="/assets/arrow-left.png" v-on:click="scrollLeft" v-if="showScrollArrows">
    
    <div class="editorial-item" v-if="showNewEditorial" @click="handleSelectEditorialNew()">
      <img
        :src="this.newNews.icon"
        class="editorial-image"
        v-if="this.newNews.icon && this.newNews.icon !== ''"
        @error="returnImageError"
      />
      <p
        class="selected-editorial-title"
        v-if="this.newNews.title && this.newNews.title !== ''"
      >
        {{ this.newNews.title }}
      </p>
    </div>

    <div
      v-for="(editoria, i) in this.editorias"
      :key="i"
      class="editorial-item"
      @click="handleSelectEditorial(editoria.id)"
    >
      <img
        :src="editoria.image"
        class="editorial-image"
        v-if="
          editoria.image != '' &&
          editoria.image != undefined &&
          editoria.image != null
        "
        @error="returnImageError"
      />
      <img v-else src="/assets/apptalkDefault.png" class="editorial-image" />
      <p
        :class="
          this.refreshSelectedEditorial == editoria.id
            ? 'selected-editorial-title'
            : 'editorial-title'
        "
      >
        {{ editoria.title }}
      </p>
    </div>

    <img class="scroll-arrow right" src="/assets/arrow-right.png" v-on:click="scrollRight" v-if="showScrollArrows">
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import FeedService from "@/services/feed.service";
import swiperNewNews from "@/components/editorial/SwiperNewNews.vue";
import { dragscroll } from "vue-dragscroll";

export default {
  components: {
    swiperNewNews
  },

  beforeUpdate() {},

  beforeMount() {
    this.getNews();
  },

  data() {
    return {
      refreshSelectedEditorial: "",
      newNews: [],
      showNewEditorial: true,
      selectedEditorialId: null,
    };
  },

  directives: {
    dragscroll,
  },

  emits: ["rendering", "getFeed"],

  props: {
    selectedEditorial: "",
    feedsLength: "",
    editorias: "",
    title: "",
    renderStatus: "",
    actualEditoria: "",
    editorialId: "",
   
  },

  computed: {
    showScrollArrows() {
      return this.editorias.length > 5;
    }
  },

  methods: {
    handleSelectEditorialNew() {
      this.$emit("handleSelectEditorialNew");
    },

    getNews() {
      FeedService.getNew().then((data) => {
        if (data.error === false) {
          this.newNews = data.data;
        } else {
          this.newNews = [];
        }
      });
    },

    handleSelectEditorial(editorialId) {
      let elemento = this.editorias.find((elemento) => elemento.id == editorialId);
      if (this.renderStatus) {
        toast.warning(this.$t("warnings.loading"));
      } else {
        // console.log("editorialId",editorialId)
         this.selectedEditorialId = editorialId;
        this.$emit("getNewFeeds", editorialId);
        this.showNewEditorial = false;
        this.$emit("rendering", editorialId);
        this.refreshSelectedEditorial = editorialId;
      }
    },

  //  handleSelectEditorial() {
  //     this.$emit('atualizar-contador', this.contador + 1);
  //   },

    // handleSelectEditorial(editorialId) {
    //    let elemento = this.editorias.find((elemento) => elemento.id == editorialId);
    //     console.log(editorialId)
    //     this.$emit("getNewFeeds", this.editorialId);
    // },

    scrollLeft() {
      const carousel = document.getElementById("carousel-editorial");
      carousel.scrollLeft -= 100;
    },
    scrollRight() {
      const carousel = document.getElementById("carousel-editorial");
      carousel.scrollLeft += 100;
    },

    returnImageError(e) {
      e.target.src = "/assets/apptalkDefault.png";
    },
  },

  emits: [ "getNewFeeds"],
};
</script>

<style>
.scroll-arrow {
 position: fixed;
 transform: translateY(-50%);
}

.scroll-arrow.left {
  left: 2px;
  cursor: pointer;
}

.scroll-arrow.right {
  right: 0;
  cursor: pointer;
}

.backBtn {
  position: fixed;
  left: 8px;
  top: 25px;
  cursor: pointer;
  transform: rotate(180deg);
}

.nextBtn {
  position: fixed;
  right: 8px;
  top: 25px;
  cursor: pointer;
}

#carousel-editorial {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow-x: auto;
}

#carousel-editorial::-webkit-scrollbar {
  width: 0px;
}

.editorial-item {
  text-align: center;
  width: 100%;
  max-width: 100px;
  height: 105px;
  cursor: pointer;
  margin-right: 15px;
}

.editorial-title {
  color: black;
  font-size: 14px;
  margin-top: 5px;
  width: 11ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-editorial-title {
  color: black;
  font-size: 14px;
  margin-top: 5px;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--yellow);
  width: 11ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editorial-image {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiper-button-next {
  color: var(--yellow);
  margin-top: -40px;
}

.swiper-button-prev {
  color: var(--yellow);
  margin-top: -40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px;
}

#carousel-editorial .swiper {
  padding-left: 14px;
  padding-right: 14px;
}
</style>
