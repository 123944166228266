<template>
  <div id="NotificationsArea">
    <div class="notificationsTooltip"></div>
    <div
      class="notificationsHolder"
      :style="{
        backgroundColor: this.statusCssNotification === 1 ? '#fff' : '#FCE2AA',
      }"
    >
      <div class="notificationsLoop">
        <div v-for="(notification, i) in this.dataNotificationUser" :key="i">
          <div
            :class="{
              notificationBox: true,
              statusOne: notification.status_notify === 1,
            }"
          >
            <div
              class="unicNotification"
              @click="
                viewNotification(
                  notification.type,
                  notification.route,
                  notification.id,
                  notification.status_notify
                )
              "
            >
              <div class="alertNotification"></div>
              <img
                v-if="notification.user_icon_uniqid != '' && notification.user_icon_uniqid.error != true "
                :src="'data:image/png;base64,' + notification.user_icon_uniqid"
                class="notificationIcon"
              />
           
              <img
                v-else
                src="/assets/profileHolderBg.svg"
                class="notificationIcon"
              />
              <div
                v-show="notification.path_editorial_image"
                style="
                  display: flex;
                  align-items: flex-end;
                  margin-left: -15px;
                  margin-top: 20px;
                "
              >
                <img
                  :src="
                    'https://api.apptalk.com.br/' +
                    notification.path_editorial_image
                  "
                  class="iconEnterprise"
                  alt=""
                />
              </div>
              <div
                v-show="notification.type === 'birthday'"
                style="
                  display: flex;
                  align-items: flex-end;
                  margin-left: -15px;
                  margin-top: 20px;
                "
              >
                <img
                  src="/assets/birthday/iconeBirthday.png"
                  class="iconEnterprise"
                  alt=""
                />
              </div>
              <div
                v-show="notification.type === 'cloud'"
                style="display: flex; align-items: flex-end; margin-left: -15px"
              >
                <img
                  src="/assets/appCloud/cloud.svg"
                  class="iconCloud"
                  alt=""
                />
              </div>
              <div
                v-show="notification.type === 'communication_ticket_finish'"
                style="display: flex; align-items: flex-end; margin-left: -15px"
              >
                <img
                  src="/assets/appCloud/comunication.svg"
                  class="iconEnterprise"
                  alt=""
                />
              </div>
              <div
                v-show="notification.type === 'communication_new_ticket'"
                style="display: flex; align-items: flex-end; margin-left: -15px"
              >
                <img
                  src="/assets/appCloud/comunication.svg"
                  class="iconEnterprise"
                  alt=""
                />
              </div>
              <div
                v-show="notification.type === 'comment'"
                style="display: flex; align-items: flex-end; margin-left: -15px"
              >
                <img
                  src="/assets/appCloud/Comentario.svg"
                  class="iconEnterprise"
                  style="width: 15px; height: 15px"
                  alt=""
                />
              </div>
              <div class="notificationInfo">
                <div
                  style="
                    white-space: nowrap;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <span class="NameUserNotification">
                    {{ notification.user_icon_name }}
                  </span>
                  <span class="DescriptionUserNotification">
                    “{{ notification.text }}”</span
                  >
                  <span class="tempNotification">
                    {{ timeSince(notification.created_at) }}</span
                  >
                </div>
              </div>
            </div>
            <hr class="notificationDivisor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserService from "@/services/user.service";
import NotificationsService from "@/services/notifications.service";
import EditoriaService from "@/services/editoria.service";

export default {
  name: "Notifications",
  beforeMount() {
    this.getNotifications();
    this.NewNotificationUser();
  },

  data() {
    return {
      notifications: [],
      notificationsIcons: [],
      dataNotificationUser: [],
      viewUserNotification: 1,
      statusCssNotification: "",
    };
  },

  methods: {
    getNotifications(userId) {
      UserService.getOwnInfo().then((data) => {
        NotificationsService.getNotifications(data.id).then((response) => {
          this.notifications = response.data;

          if (this.notifications.length > 0) {
            this.$emit("hasNotification");
          }

          this.notifications.forEach((element, i) => {
            this.notificationsIcons[i] = true;
          });

          this.$forceUpdate();
        });
      });
    },

    NewNotificationUser() {
      NotificationsService.getNewNotifications().then((data) => {
        this.dataNotificationUser = data.data;
        this.statusCssNotification = data.data[0].status_notify;
        this.dataNotificationUser.forEach(function (item) {
          let imageIdUser = item["user_icon_uniqid"];
          if (imageIdUser != null && imageIdUser.length > 0) {
            EditoriaService.getUserImage(imageIdUser).then((data) => {
              item["user_icon_uniqid"] = data;
            });
          }
        });
        console.log(this.dataNotificationUser);
      });
    },

    viewNotification(type, route, id) {
      NotificationsService.confirmationViewNotification(
        id,
        this.viewUserNotification
      ).then((response) => {
        if (!response.error) {
          if (type === "cloud") {
            this.$router.push("/appCloud");
          }
          if (type === "feed") {
            const data = JSON.parse(route);
            this.$router.push(`/post/${data.id_editoria}/${data.id_feed}`);
          }
          if (type === "birthday") {
            this.$router.push("/birthdayUser");
          }
          if (type === "communication_ticket_finish") {
            this.$router.push("/communication");
          }
          if (type === "comment") {
            const data = JSON.parse(route);
            this.$router.push(`/post/${data.id_editoria}/${data.id_feed}`);
            
          }
          if (type === "reaction") {
            const data = JSON.parse(route);
            this.$router.push(`/post/${data.id_editoria}/${data.id_feed}`);
           
          }
          if (type === "communication_new_ticket") {
            this.$router.push("/communication");
          }
        }
      });
    },
    timeSince(created_at) {
      const now = new Date();
      const createdAtDate = new Date(created_at);
      const differenceMs = now - createdAtDate;

      const seconds = Math.floor(differenceMs / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (days > 0) {
        return `${days} dias`;
      } else if (hours > 0) {
        return `${hours} horas`;
      } else if (minutes > 0) {
        return `${minutes} minutos`;
      } else {
        return `${seconds} segundos`;
      }
    },
  },
};
</script>

<style>
.noNotificationHolder {
  width: 99%;
  text-align: center;
  margin-bottom: 12px;
}

.noNotificationText {
  color: rgba(147, 147, 147, 1);
  margin: auto;
}

.markAsRead {
  margin: auto;
  float: right;
  margin-right: 8px;
}

.markAsRead p {
  margin-bottom: 0;
  margin-top: -6px;
  margin-bottom: 7px;
  color: rgba(147, 147, 147, 1);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}

.notificationDivisorLast {
  background-color: #d8d8d8;
  height: 2px;
  margin: auto;
  margin-bottom: 13px;
  border: none;
}

.NameUserNotification {
  opacity: 1;
  color: rgba(29, 29, 29, 1);
  font-size: 10px;
  font-weight: 900;
  font-style: ExtraBold;
  letter-spacing: 0px;
  text-align: left;
}

.DescriptionUserNotification {
  opacity: 1;
  color: rgba(29, 29, 29, 1);
  font-size: 10px;
  font-weight: 600;
  font-style: ExtraBold;
  letter-spacing: 0px;
  text-align: left;
  white-space: normal;
}

#NotificationsArea {
  width: 0px;
  position: relative;
}

.notificationDate {
  font-size: 12px;
  color: #939393;
}

.notificationMessage {
  margin-bottom: 0px;
  font-size: 14px;
  color: #939393;
}

.notificationInfo {
  width: 185px;
  margin-left: 10px;
  margin-bottom: -23px;
  cursor: pointer;
}

.unicNotification {
  width: 94%;
  margin: auto;
  margin-bottom: 22px;
  display: flex;
  position: relative;
}

.notificationIcon {
  width: 43px;
  cursor: pointer;
  height: 43px;
  border-radius: 50px;
}

.notificationDivisor {
  background-color: #b4abab;
  height: 2px;
  margin: auto;
  width: 90%;
  margin-top: -5px;
  border: none;
}

.notificationsLoop {
  max-height: 435px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* width */
.notificationsLoop::-webkit-scrollbar {
  width: 2px;
}

.notificationsHolder {
  border-radius: 5px;
  border: 1px solid var(--dark-grey);
  max-height: 520px;
  margin: 20px;
  width: 320px;
  position: absolute;
  top: 40px;
  right: -20px;
  overflow-x: hidden;
  padding-top: 12px;
}

.notificationsTooltip {
  background-color: white;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 55%,
    rgba(238, 0, 37, 0) 50%
  );
  border-left: 1px solid var(--dark-grey);
  border-bottom: 1px solid var(--dark-grey);
  content: "\00a0";
  display: block;
  height: 20px;
  left: -83px;
  top: 50.2px;
  z-index: 1;
  position: relative;
  -webkit-transform: rotate(135deg);
  width: 20px;
}

.deleteNotificationHandler {
  position: absolute;
  top: 5px;
  right: 2px;
  cursor: pointer;
}
.DescriptionText {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0, 11px;
  text-align: center;
}
.allNotifications {
  cursor: pointer;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 17px;
  font-weight: 600;
  font-style: SemiBold;
  letter-spacing: -0, 11px;
  text-align: center;
}
.allNotifications:hover {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 249, 221, 1);
  opacity: 1;
  color: rgba(250, 185, 0, 1);
  font-size: 17px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: -0, 11px;
  text-align: center;
  padding: 10px 0px 0px 0px;
  width: 90px;
  height: 40px;
}

.tempNotification {
  opacity: 1;
  color: rgba(29, 29, 29, 1);
  font-size: 11px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
}

.iconEnterprise {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 30px;
}

.iconCloud {
  width: 20px;
  height: 20px;
  background-color: #fab900;
  border-radius: 30px;
}

.notificationBox {
  background-color: #fce2aa;
  width: 420px;
  height: 70px;
  padding: 10px 0px 0px 0px;
}

.notificationBox.statusOne {
  background-color: #fff;
}

.notificationBox:hover {
  background-color: #f5f5f5; /* Default hover color */
}

.notificationBox.statusOne:hover {
  background-color: #f5f5f5; /* Hover color for status 1 */
}
</style>
