<template>
  <div class="modalBackdrop">
    <div class="modalChange">
      <div class="pseudoHeader">
        <h2 class="changeCompanyTitle">{{ $t("changeCompany") }}</h2>
        <button @click="close" class="closeBtn">X</button>
      </div>
      <div class="companiesImages">
        <div @click="
          showDialog(
            representative.company.id_representante,
            representative.user.id,
            representative.company.name
          )
          " class="representativeHolder" v-for="(representative, i) in this.allRepresentatives" :key="i">
          <img :src="representative.company.icon" class="representativeImage" />
          <!-- <p>{{ representative.company.name }}</p> -->
        </div>
      </div>
    </div>
    <div class="internalBackdrop" v-if="confirmDialog">
      <div class="confirmDialog">
        <div class="first-rowAlert">
          <p class="alert-text">
            Deseja mudar para empresa
            <strong>{{ this.selectedCompanyName }}</strong>?
          </p>
        </div>
        <div class="second-rowAlert">
          <hr />
          <button class="alertBtn left" @click="showDialog">
            {{ $t("communicationTexts.cancel") }}
          </button>
          <button class="alertBtn right" @click="selectCompany">
            Mudar de empresa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    allRepresentatives: "",
  },
  data() {
    return {
      confirmDialog: false,
      selectedCompany: "",
      selectedCompanyName: "",
      newUserId: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showDialog(representativeId, newUserId, companyName) {
      this.confirmDialog = !this.confirmDialog;

      this.selectedCompany = representativeId;
      this.selectedCompanyName = companyName;
      this.newUserId = newUserId;
    },

    selectCompany() {
      // console.log("Representative Id:", representativeId)
      this.$emit("selectCompany", this.selectedCompany, this.newUserId);
      this.$emit("close");
    },
  },
};
</script>

<style>
.modalChange::-webkit-scrollbar {
  width: 20px;
}

.modalChange::-webkit-scrollbar-track {
  background-color: transparent;
}

.modalChange::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.modalChange::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.modalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.internalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.first-rowAlert {
  height: 80px;
  display: flex;
  align-items: center;
}

.confirmDialog {
  border-radius: 1rem;
  width: 430px;
  height: auto;
  display: grid;
  grid-template-rows: 1fr -webkit-max-content;
  grid-template-rows: 1fr max-content;
  background: var(--white);
  box-shadow: var(--container-box-shadow);
  text-align: center;
  padding-top: 5px;
}

.second-rowAlert {
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid var(--button-border-color);
  height: 50px;
}

.modalChange {
  background: var(--white);
  width: 1000px;
  height: 510px;
  border-radius: 4px;
  border: none;
}

.alertBtn {
  background: white;
  border: none;
  width: 50%;
  font-weight: bold;
  border-radius: 0;
}

.alertBtn.left {
  border-right: 1px solid var(--button-border-color);
  border-bottom-left-radius: 1rem;
  color: var(--red);
}

.alertBtn.right {
  border-bottom-right-radius: 1rem;
  color: var(--blue);
}

.alert-text {
  font-size: 18px;
  width: 100%;
  text-align: center;
  line-height: normal;
  margin: auto;
}

.companiesImages {
  height: 390px;
  display: flex;
  margin: auto;
  width: 925px;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: -12px;
}

.representativeHolder {
  text-align: center;
  width: 288px;
  height: 90px;
  padding: 0;
  margin: auto;
  margin-bottom: 11px;
  margin-top: 11px;
  cursor: pointer;
}

.closeBtn {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
  color: var(--white);
  margin: 32px;
  font-size: 20px;
}

.changeCompanyTitle {
  margin: 32px;
}

.pseudoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.representativeImage {
  width: 190px;
  height: 95px;
  margin-bottom: 5px;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>
