import { ref } from "vue";
import { defineStore } from "pinia";
import { ApiService } from "@/services";
import { AES, enc } from "crypto-js";
import axios from "axios";

export const useAuth = defineStore("auth", () => {
  const token = ref(localStorage.getItem("_apptalk_access_token"));
  const logged = ref(localStorage.getItem("logged"));
  const representativeId = ref(localStorage.getItem("representative"));

  // SECRET_KEY
  const SECRET_KEY = "zbTtuYawpT6htlizS8GmjH8KSizcItPy";

  function setToken(tokenValue) {
    // ENCRYPT
    const encryptedString = AES.encrypt(tokenValue, SECRET_KEY);

    localStorage.setItem("_apptalk_access_token", encryptedString.toString());

    token.value = tokenValue;

    localStorage.setItem("logged", true);
    logged.value = true;

    localStorage.removeItem("representative");
    representativeId.value = "";
  }

  function logoutUser() {
    localStorage.removeItem("_apptalk_access_token");
    token.value = "";
    localStorage.setItem("logged", false);
    logged.value = false;
    localStorage.removeItem("representative");
    representativeId.value = "";
  }

  async function verifyToken() {
    try {
      const decrypted = AES.decrypt(
        localStorage.getItem("_apptalk_access_token"),
        SECRET_KEY
      );
      const decryptedString = decrypted.toString(enc.Utf8);

      const tokenAuth = "Bearer " + decryptedString;
      return await ApiService.customRequest({
        method: "get",
        url: "/app/v1/user/info",
        data: null,
        headers: {
          Authorization: tokenAuth,
        },
      }).then((response) => response.data);
    } catch (error) {
      // console.log("Error:", error);
    }
  }

  return {
    setToken,
    verifyToken,
    logoutUser,
    token,
    logged,
  };
});
