export default {
  "tryAgainLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed. Please try again!"])},
  "newMarksTxts": {
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage indicators"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status allow you to quickly categorize and identify important information on each ticket. Whether it's to highlight priorities, track status or simply make it easier to organize"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add indicator"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "padrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default indicator"])},
    "turnPadrao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make default"])},
    "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "padraoDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "kanbanAtualizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status updated successfully!"])}
  },
  "ssoOptions": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitate your access"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an option below to create your account"])}
  },
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "0072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to create your apptalk account, please contact the administrator!"])},
  "0070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to access Apptalk!"])},
  "errorCaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please validate the reCAPTCHA to proceed"])},
  "social_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with social accounts"])},
  "social_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create with social accounts"])},
  "sso": {
    "login": {
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Login with ", _interpolate(_list(0)), " successfully!"])},
      "successCreation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Account created with ", _interpolate(_list(0)), " successfully!"])},
      "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Account ", _interpolate(_list(0)), " not found!"])},
      "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to log in with account. Try again"])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account synchronized successfully!"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Unable to sync ", _interpolate(_list(0)), "!"])},
    "error1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Email ", _interpolate(_list(0)), " already in use!"])},
    "error7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not added to the Azure platform!"])}
  },
  "sync": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected as"])},
    "no1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link with"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize"])},
    "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsync"])},
    "delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Account ", _interpolate(_list(0)), " successfully removed!"])},
    "delete_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error removing account ", _interpolate(_list(0)), "!"])}
  },
  "createNewTicketText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new ticket"])},
  "handleMarkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage marks"])},
  "recusarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline transfer"])},
  "aceitarTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfer"])},
  "assumir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assume ticket"])},
  "assumirSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket successfully accepted"])},
  "transferDenyNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer successfully declined!"])},
  "transferNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer request successfully completed!"])},
  "waitTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket in progress, wait for more updates"])},
  "waitTransferAdm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your transfer request to the channel: "])},
  "foiRealizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is being analyzed"])},
  "waitingMod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket awaiting moderator"])},
  "waitingAprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferred: "])},
  "transferRequestIndicator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting transfer approval: "])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field cannot be empty"])},
  "handleTicket": {
    "finalizarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End ticket"])},
    "encerrarTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End ticket"])},
    "transferirChamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which channel do you want to transfer the call to?"])},
    "solicitarApoio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request support"])},
    "apoioText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which channel do you want to request support for?"])},
    "historico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "anotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your conclusion"])},
    "successNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note sent successfully"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation"])},
    "motivation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explanation:"])}
  },
  "chamados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tickets"])},
  "chamado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ticket"])},
  "marcadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "avaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "arquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To file"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Apptalk on your mobile device"])},
  "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install app on android device"])},
  "Ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install app on ios device"])},
  "createNewTicket": {
    "selectAssunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the subject of the ticket:"])},
    "selectAssunto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select subject:"])},
    "outro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "openNewTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new ticket"])},
    "needSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a subject to continue"])}
  },
  "createUser": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the invitation code received"])},
    "completeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the data to create your account"])},
    "validCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated code"])}
  },
  "personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])},
  "sobreMim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About me"])},
  "dadosEmpresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company data"])},
  "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplify your access by connecting your social networks to our system"])},
  "newCommunication": {
    "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the subject in detail below, someone will contact you shortly and you will be notified"])}
  },
  "actions": {
    "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status updated successfully"])},
    "complaintCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint made successfully!"])},
    "complaintNonCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to report!"])},
    "finishComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report deleted successfully"])},
    "notificationDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification deleted successfully!"])},
    "notificationsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications deleted successfully!"])},
    "profileEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile successfully edited!"])},
    "successComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment sent!"])},
    "successCreatedTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket created!"])},
    "successFinishTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket completed!"])},
    "successPostDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post deleted successfully"])},
    "successPostSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post enviado com sucesso"])},
    "successSendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent!"])},
    "successTransferTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket transferred successfully"])},
    "termoAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term successfully accepted!"])},
    "sucessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token sent successfully!"])},
    "sucessToken1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully verified token!"])},
    "successPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully!"])},
    "sucessParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinion added successfully!"])},
    "successAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service evaluated successfully!"])},
    "successArquivar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket filed successfully!"])}
  },
  "noPossiblePass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update password!"])},
  "verificando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying token..."])},
  "invalidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token!"])},
  "noFeedOnEditorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This editorial has no publications"])},
  "tokenTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a moment to try again"])},
  "adminPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin panel"])},
  "AdmOmbudsOpts": {
    "denounce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "noArch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This complaint does not have any attachments"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "responseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your conclusion:"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe what happened:"])}
  },
  "apptalk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apptalk ® 2024"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "biografia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biography"])},
  "videoOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the awareness stage!"])},
  "videoOmbuds1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before proceeding with your report, watch these informational videos to better understand the context and issues surrounding the matter"])},
  "changeCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change companies"])},
  "changePass": {
    "cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "confirmId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your identity"])},
    "findAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your account"])},
    "insertCell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your phone to change your password"])},
    "insertCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code we sent you"])},
    "newPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password"])},
    "req1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 special character"])},
    "req2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 uppercase character"])},
    "req3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 lowercase character"])},
    "req4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 numeric character"])},
    "sucessId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity confirmed successfully"])},
    "empresas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
    "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the company you want to login"])}
  },
  "changeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "typeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your message..."])},
  "colab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator"])},
  "loadingChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comments"])},
  "commentsUpper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "commentVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication channel"])},
  "countrys": {
    "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
    "AF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
    "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "AM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "AN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antilhas Holandesas"])},
    "AO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "AQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antártida"])},
    "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "AU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "AX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Aland"])},
    "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "BA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
    "BB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "BF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "BH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "BI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "BJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "BL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Bartolomeu"])},
    "BN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "BO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "BQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonaire, Santo Eustáquio e Saba"])},
    "BR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "BT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "BV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Bouvet"])},
    "BW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "BZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cocos (Keeling)"])},
    "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo (Democratic Republic)"])},
    "CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
    "CG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo (Republic)"])},
    "CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "CI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d'Ivoire"])},
    "CL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "CM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "CN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "CR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "CU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Christmas"])},
    "CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "DJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "DM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
    "DZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "EC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "EG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "ER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "ET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "FJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "FK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Malvinas"])},
    "FM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "FO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Faroe"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "GB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "GD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "GE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "GF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiana Francesa"])},
    "GG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guernsey"])},
    "GH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "GM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "GN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "GQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
    "GR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "GS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Geórgia do Sul e Sandwich do Sul"])},
    "GT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
    "GW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "GY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "HM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Heard e Ilhas McDonald"])},
    "HN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha de Man"])},
    "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "IO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Território Britânico do Oceano Índico"])},
    "IQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "IR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "JE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jersey"])},
    "JM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "JO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "JP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "KE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "KG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "KH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
    "KI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "KM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "KN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
    "KP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Korea"])},
    "KR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
    "KW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Cayman"])},
    "KZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "LB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "LI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "LK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "LR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "LS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "LY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho"])},
    "MG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "MH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
    "MK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Macedonia"])},
    "ML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "MM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myanmar (Burma)"])},
    "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macau"])},
    "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Marianas do Norte"])},
    "MQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martinica"])},
    "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montserrat"])},
    "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "MU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "MV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "MW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "MY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "MZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Caledônia"])},
    "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "NF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilha Norfolk"])},
    "NG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "NI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "NR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niue"])},
    "NZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "OM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "PG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "PH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "PK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "PN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Pitcairn"])},
    "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "PW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "PY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "QA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "RE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunião"])},
    "RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "RU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russia"])},
    "RW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
    "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "SG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "SH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santa Helena"])},
    "SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "SJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svalbard e Jan Mayen"])},
    "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "SM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "SN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "SO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "SR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "SS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
    "ST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Tomé and Príncipe"])},
    "SV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "SX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São Martinho (lado holandês)"])},
    "SY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "SZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "TC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Turks e Caicos"])},
    "TD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "TF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territórios Franceses do Sul"])},
    "TG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "TJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "TK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokelau"])},
    "TL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor-Leste"])},
    "TM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "TT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
    "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "TW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan"])},
    "TZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "UG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States of America"])},
    "UY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "UZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican City"])},
    "VC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
    "VE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "VG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Britânicas"])},
    "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhas Virgens Americanas"])},
    "VN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "VU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])},
    "WF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallis e Futuna"])},
    "WS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "YE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "ZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "ZM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "ZW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])}
  },
  "createPostPlaceHldr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is on your mind, "])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" day"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" days"])},
  "denounceVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denounce post"])},
  "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
  "entryManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access as manager"])},
  "entryUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access as user"])},
  "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "firstAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a code"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "genders": {
    "fem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminine"])},
    "masc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculine"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "genero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to main page"])},
  "userdoNotExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found on the platform!"])},
  "sendingToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending token..."])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "noFeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, but you don't have access to any posts"])},
  "noFeed1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the administrator"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
  "likeVerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error! Invalid credentials"])},
  "loginText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apptalk helps your company connect with its employees in a simple and innovative way."])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "mandatoryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obrigatório"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" month"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" months"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, it looks like the page you're looking for was not found. This could be because the page address was misspelled or because the page was removed"])},
  "noTicketsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tickets created in this communication channel"])},
  "notifications": {
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulate the birthdays of the day!"])},
    "birthdayReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have new congratulations messages!"])},
    "noNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no new notifications at the moment 📭"])}
  },
  "ombudsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ombudsman"])},
  "ombudsmanTexts": {
    "acceptConduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with the code of conduct"])},
    "acceptTermo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with the privacy term"])},
    "adm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "arquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "ativos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking here!"])},
    "conduta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code of Conduct"])},
    "errorAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the term to continue."])},
    "firstMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unseen messages"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the channel you want to join"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to identify yourself?"])},
    "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report what happened"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "next1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "noOmbuds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company does not have an ombudsman channel available"])},
    "resposta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "secondMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["messages under review"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also select the file"])},
    "sendAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send at: "])},
    "termo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy term"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "openArquivados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived tickets"])},
  "noParecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comments added to this report"])},
  "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tickets"])},
  "parecer": {
    "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusion"])},
    "noConclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This complaint was finalized without an opinion"])}
  },
  "passwordPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "principalPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main page"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "profile": {
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "departamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
  },
  "userInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully registered!"])},
  "userInviteFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to register user!"])},
  "profileEditError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update your profile"])},
  "communicationTexts": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "finish1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return ticket"])},
    "wantToFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to finalize ticket?"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very satisfied!"])},
  "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not satisfied"])},
  "noPossibleAvaliar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to rate!"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True"])},
  "typeComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type a comment..."])},
  "userPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Phone"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value: "])},
  "videoNoSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your browser does not support the video player"])},
  "vueTel": {
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex:"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "selecCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])}
  },
  "warnings": {
    "noContentPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter post content"])},
    "uploadingPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading the post"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait for the end of loading"])}
  },
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" week"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" weeks"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" year"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" years"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])}
}