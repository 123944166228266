import { ref } from "vue";
import axios from "axios";
import router from "@/router";
import { useAuth } from "@/store/auth.js";
import { AES, enc } from "crypto-js";
import { saveAs } from "file-saver";

const token = ref(localStorage.getItem("_apptalk_access_token"));
const logged = ref(localStorage.getItem("logged"));
const tokenAuth = "Bearer " + token.value;

axios.interceptors.response.use(null, function (error) {
  if (error.response.status == 401) {
    const auth = useAuth();
    auth.logoutUser();
    router.push("/login");
  }
  return Promise.reject(error);
});

export const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  customRequest(data) {
    data.withCredentials = true;
    return axios(data);
  },

  setHeader(tokenValue) {
    // console.log("Token Value", tokenValue);
    // Method resposible for set authorization token

    if (tokenValue != null) {
      // SECRET_KEY
      const SECRET_KEY = "zbTtuYawpT6htlizS8GmjH8KSizcItPy";

      const decrypted = AES.decrypt(tokenValue, SECRET_KEY);

      const decryptedString = decrypted.toString(enc.Utf8);

      axios.defaults.headers.common = {
        Authorization: `Bearer ${decryptedString}`,
      };
    }
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource, { withCredentials: true });
  },

  post(resource, data) {
    return axios.post(resource, data, { withCredentials: true });
  },

  put(resource, data) {
    return axios.put(resource, data, { withCredentials: true });
  },

  delete(resource) {
    return axios.delete(resource, { withCredentials: true });
  },

  fetch(endpoint) {
    const SECRET_KEY = "zbTtuYawpT6htlizS8GmjH8KSizcItPy";

    const token = ref(localStorage.getItem("_apptalk_access_token"));

    const decrypted = AES.decrypt(token._value, SECRET_KEY);

    const decryptedString = decrypted.toString(enc.Utf8);

    // Faça a requisição HTTP GET para o endpoint
    fetch(endpoint, {
      headers: { Authorization: `Bearer ${decryptedString}` },
    })
      .then((response) => response.blob()) // converte a resposta em um objeto Blob
      .then((blob) => {
        // Cria um objeto de URL de dados a partir do objeto Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Use a biblioteca FileSaver.js para baixar o arquivo
        saveAs(pdfUrl);

        // Libera o objeto de URL de dados da memória
        URL.revokeObjectURL(pdfUrl);
      })
      .catch((error) => console.error(error));
  },
};

export default ApiService;
