import { ApiService } from "./index";

class FeedService {
  getComments(editorialId, feedId) {
    return ApiService.get(
      `/app/v1/editorial/comments/${editorialId}/${feedId}`
    ).then((data) => data);
  }

  commentPost(editorialId, feedId, data) {
    const formData = new FormData();

    formData.append("comment", data);

    return ApiService.post(
      `/app/v1/editorial/comment/${editorialId}/${feedId}`,
      formData
    ).then((response) => response);
  }

  
  getNew() {
    return ApiService.get("/app/v1/editorial/news/v3").then((response) => response.data);
  }

}

export default new FeedService();
