import Vue from "vue";
import { ref } from "vue";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import mitt from "mitt";
import { createPinia } from "pinia";
import { ApiService } from "./services/api.service.js";
import i18n from "./plugins/i18n";

import { install } from "vue3-recaptcha-v2";

import "maz-ui/css/main.css";

import "bootstrap/dist/css/bootstrap.css";

import VueDragscroll from "vue-dragscroll";

const token = ref(localStorage.getItem("_apptalk_access_token"));

ApiService.init(process.env.VUE_APP_BACKEND_SERVER);
ApiService.setHeader(token.value);

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.config.warnHandler = () => null;

app
  .use(install, {
    sitekey: "6Lec_vspAAAAAIa2MRnfLd7OiKA9iXd_vbupAFIN",
    cnDomains: false, // Optional, If you use in China, set this value true
  })
  .use(i18n)
  .use(router)
  .use(VueDragscroll)
  .use(createPinia())
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
