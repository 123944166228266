import { ApiService } from "./index";
import { ref } from "vue";

class EditoriaService {
  getEditorias(representanteId) {
    return ApiService.get(`/app/v1/editorial/all/${representanteId}`).then(
      (response) => response
    );
  }

  getFeeds(editorialId, page) {
    return ApiService.get(`/app/v4/editorial/feeds/${editorialId}/new?page=${page}`).then(
      (response) => response.data
    );
  }

  getFeedsNew(editorialId, page) {
    return ApiService.get(
      `/app/v5/editorial/feeds/${editorialId}/apptalk?page=${page} `
    ).then((response) => response.data);
  }
  getFeedImage(uniqId) {
    return ApiService.get(`/app/v5/editorial/feeds/images/${uniqId} `).then(
      (response) => response.data
    );
  }

  getUserImage(oldUniqId) {
    return ApiService.get(`/app/v1/user/picture/${oldUniqId}`).then(
      (response) => response.data
    );
  }

  getAllReactions() {
    return ApiService.get(`/app/v1/reactions/all`).then(
      (response) => response.data
    );
  }

  reactFeed(editorialId, feedId, reactcode) {
    const formData = new FormData();
    formData.append("reaction_code", reactcode);

    // console.log(editorialId);
    // console.log(feedId);

    return ApiService.post(
      `/app/v1/editorial/react/${editorialId}/${feedId}`,
      formData
    ).then((response) => response.data);
  }

  getReactionsByPost(editorialId, feedId) {
    return ApiService.get(
      `/app/v1/editorial/reactions/${editorialId}/${feedId}`
    ).then((response) => response.data);
  }

  denoucePost(editorialId, feedId) {
    return ApiService.post(
      `/app/v1/editorial/report/${editorialId}/${feedId}`
    ).then((response) => response.data);
  }

  createPost(editorialId, data) {
    const formData = new FormData();

    formData.append("text", data.text);

    for (let i = 0; i < data.content.length; i++) {
      if (
        data.content[i].type == "image/png" ||
        data.content[i].type == "image/jpeg" ||
        data.content[i].type == "image/gif"
      ) {
        formData.append("images[]", data.content[i]);
      }

      if (
        data.content[i].type == "video/mp4" ||
        data.content[i].type == "video/mov" ||
        data.content[i].type == "video/amv"
      ) {
        formData.append("videos[]", data.content[i]);
      }
     
    }

    return ApiService.post(
      `/app/v1/editorial/feeds/new/${editorialId}`,
      formData
    ).then((response) => response.data);
  }
  

  
  async createFile(editorialId, feedId, data) {
    try {
      const formData = new FormData();
      for (let i = 0; i < data.content.length; i++) {
        formData.append("files[" + i + "]", data.content[i]);
      }
       
        let response =  await ApiService.post(
          `/app/v1/editorial/${editorialId}/post/${feedId}/files`,
          formData
        );
        if (response.status != 200) {
          // tratar o erro

          return false;
        }
      
      return true;
    } catch (error) {
      return false;
    }
  }

  createPostNew(editorialId, data, agendamento) {
    const formData = new FormData();

    formData.append("text", data.text);
    formData.append("agendamento", agendamento);

    return ApiService.post(
      `/app/v1/editorial/feeds/new/${editorialId}`,
      formData
    ).then((response) => response.data);
  }

  deletePost(editorialId, feedId) {
    return ApiService.post(
      `/app/v1/editorial/feeds/delete/${editorialId}/${feedId}`
    ).then((response) => response.data);
  }
}
export default new EditoriaService();
