import { ApiService } from "./index";

class UserService {
  getInfoById(userId) {
    return ApiService.get(`/app/v1/user/info/${userId}`).then(
      (data) => data.data
    );
  }

  newUserInformations(data) {
    const formdata = new FormData();

    formdata.append("firstname", data.firstname);
    formdata.append("lastname", data.lastname);
    formdata.append("password", data.password);
    formdata.append("email", data.email);
    formdata.append("phone", data.number);
    formdata.append("id_banco", data.database_id);
    formdata.append("id_representante", data.id_representante);

    return ApiService.post(`/app/v2/user/register`, formdata).then(
      (response) => response.data
    );
  }

  validateNewUserCode(token) {
    const formdata = new FormData();

    formdata.append("invite_code", token);

    return ApiService.post(`/app/v2/invite/verify`, formdata).then(
      (response) => response.data
    );
  }

  createNewPassword(phone, newPassword) {
    const formdata = new FormData();

    formdata.append("cellphone", phone);
    formdata.append("password", newPassword);

    return ApiService.post(`/app/v3/user/reset-password`, formdata).then(
      (response) => response.data
    );
  }

  verifyToken(phone, token) {
    const formdata = new FormData();

    formdata.append("cellphone", phone);
    formdata.append("token", token);

    return ApiService.post(`/app/v3/user/verify-token`, formdata).then(
      (response) => response.data
    );
  }

  startUserResetPass(phone) {
    const formdata = new FormData();

    formdata.append("cellphone", phone);

    return ApiService.post(`/app/v3/user/send-reset-token`, formdata).then(
      (data) => data.data
    );
  }

  createAdicionais(questionId, answer) {
    const formdata = new FormData();

    formdata.append("answer", answer);

    return ApiService.post(
      `/app/v1/question/${questionId}/create/answers`,
      formdata
    ).then((response) => response.data);
  }

  createOpcionais(bio, genero) {
    const formdata = new FormData();

    formdata.append("biografia", bio);
    formdata.append("genero", genero);

    return ApiService.post(`/app/v1/user/optional/store`, formdata).then(
      (response) => response.data
    );
  }

  getOpcionais() {
    return ApiService.get(`/app/v1/user/optional`).then((data) => data.data);
  }

  getAdicionais() {
    return ApiService.get(`/app/v1/question`).then((data) => data.data);
  }

  checkInfoStatus() {
    return ApiService.get(`/app/v1/user/optional/checking`).then(
      (data) => data.data
    );
  }

  changeImageUser(image) {
    const formdata = new FormData();

    formdata.append("imagem", image);

    return ApiService.post(`/app/v1/user/picture/update`, formdata);
  }

  updateProfileUser(data, birthday) {
    const formdata = new FormData();

    // Basics
    formdata.append("representative_id", data.id_representante);
    formdata.append("user_id", data.id);

    // Mandatory
    formdata.append("fullname", data.nome);
    formdata.append("email", data.email);
    formdata.append("birthdate", birthday);
    formdata.append("department", data.setor);
    formdata.append("role", data.cargo);

    return ApiService.customRequest({
      method: "post",
      url: "/app/v1/user/update",
      data: formdata,
      headers: {
        DatabaseId: data.id_banco,
      },
    }).then((response) => response.data);
  }

  getOwnInfo() {
    return ApiService.customRequest({
      method: "get",
      url: "/app/v1/user/info",
      data: null,
    }).then((data) => data.data);
  }

  getOwnImage() {
    return ApiService.get(`/app/v1/user/picture/my/size/Medium/desk`).then(
      (data) => data.data
    );
  }

  getRepresentativeInfo() {
    return ApiService.get(`/app/v1/representative`).then((data) => data.data);
  }

  getAllRepresentativeInfo() {
    return ApiService.get(`/app/v3/auth/companies`).then((data) => data.data);
  }

  // NEW USER CONSUMPTION
  newGetInfo() {
    return ApiService.get("app/v1/user/new-info").then((data) => data.data);
  }
}
export default new UserService();
