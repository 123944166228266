<template>
  <div class="createpost-container">
    <div class="first-row"  >
      <div id="nav-user"  >
        <svg
          width="34"
          height="34"
          viewBox="0 0 24 24"
          class="right-button-icon"
          v-if="userImage == undefined || userImage == ''"
        >
          <path
            d="M17.754 14a2.249 2.249 0 0 1 2.249 2.25v.918a2.75 2.75 0 0 1-.513 1.598c-1.545 2.164-4.07 3.235-7.49 3.235c-3.421 0-5.944-1.072-7.486-3.236a2.75 2.75 0 0 1-.51-1.596v-.92A2.249 2.249 0 0 1 6.251 14h11.502ZM12 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10Z"
          />
        </svg>
        <img :src="userImage" class="userProfilePic" v-else />
      </div>


      <input
        type="text"
        :placeholder="$t('createPostPlaceHldr') + userName + '?'"
        id="input-text"
        v-model="this.newPostInfo.text"
      />


      <div class="sendMsgBtn" >
        <div class="svgSend" id="svgSend" @click="createPost(this.editorialId)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            class="sendSvgImg"
          >
            <path
              fill="#ffff"
              fill-rule="evenodd"
              d="M2.345 2.245a1 1 0 0 1 1.102-.14l18 9a1 1 0 0 1 0 1.79l-18 9a1 1 0 0 1-1.396-1.211L4.613 13H10a1 1 0 1 0 0-2H4.613L2.05 3.316a1 1 0 0 1 .294-1.071z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>


    <swiper
      :slides-per-view="1"
      :space-between="5"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :modules="modules"
      :roundLengths="false"
      :pagination="{
        clickable: true,
      }"
      class="post-swiper-createPost"
      id="swiper-createpost"
    >
      <swiper-slide
       
        class="post-swiper-slide-createPost"
        v-for="(image, i) in this.newPostInfo.content"
        :key="i"
      >
        <div class="image-holder">
          <div class="delete-image-preview" @click="deleteImageFromContent(i)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffff"
                d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"
              />
            </svg>
          </div>
          <img
            v-bind:ref="'image' + parseInt(i)"
            class="inner-image-holder"
            v-if="
              image.type == 'image/png' ||
              image.type == 'image/jpeg' ||
              image.type == 'image/gif'
            "
          />

          <video
            id="video-preview"
            muted
            controls
            v-if="image.type == 'video/mp4' || image.type == 'video/amv'"
            class="inner-image-holder"
            v-bind:ref="'image' + parseInt(i)"
          ></video>
        </div>
      </swiper-slide>
    </swiper>


    <hr />


    <div class="second-row" >
      <div class="item-div" @click="this.handleCamera()">
        <svg width="36" height="36" viewBox="0 0 24 24" class="item">
          <path
            d="M17.5 12a5.5 5.5 0 1 1 0 11a5.5 5.5 0 0 1 0-11Zm0 2l-.09.008a.5.5 0 0 0-.402.402L17 14.5V17h-2.502l-.09.009a.5.5 0 0 0-.402.402l-.008.09l.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008l.09-.008a.5.5 0 0 0 .402-.402l.008-.09V18h2.504l.09-.007a.5.5 0 0 0 .402-.402l.008-.09l-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 14ZM13.925 2.504a2.25 2.25 0 0 1 1.94 1.11l.814 1.387h2.071A3.25 3.25 0 0 1 22 8.25v4.56a6.52 6.52 0 0 0-1.499-1.077L20.5 8.25a1.75 1.75 0 0 0-1.75-1.75h-2.5a.75.75 0 0 1-.647-.37l-1.032-1.757a.75.75 0 0 0-.646-.37h-3.803a.75.75 0 0 0-.574.268l-.065.09L8.39 6.142a.75.75 0 0 1-.639.358h-2.5A1.75 1.75 0 0 0 3.5 8.25v9.5c0 .966.784 1.75 1.75 1.75h6.063c.173.533.412 1.037.709 1.5H5.25A3.25 3.25 0 0 1 2 17.75v-9.5A3.25 3.25 0 0 1 5.25 5h2.08l.875-1.424a2.25 2.25 0 0 1 1.917-1.073h3.803ZM12 8a4.502 4.502 0 0 1 4.283 3.114c-.5.095-.98.247-1.433.449A2.999 2.999 0 0 0 9 12.5c0 1.43 1 2.625 2.338 2.927a6.446 6.446 0 0 0-.31 1.467A4.501 4.501 0 0 1 12 8.001Z"
          />
        </svg>
        <p class="item-text">{{ $t("photo") }}</p>
      </div>


      <div class="item-div" style="border-left: 1px solid #c8c9ca">
        <label for="create-post-file" class="create-post-file">
          <svg width="34" height="34" viewBox="0 0 24 24" class="item">
            <g fill="none">
              <path
                d="M5.25 3A2.25 2.25 0 0 0 3 5.25v6h8.25V3h-6z"
                class="item"
              />
              <path
                d="M12.75 3v8.25H21v-6A2.25 2.25 0 0 0 18.75 3h-6z"
                class="item"
              />
              <path
                d="M21 12.75h-8.25V21h6A2.25 2.25 0 0 0 21 18.75v-6z"
                class="item"
              />
              <path
                d="M11.25 21v-8.25H3v6A2.25 2.25 0 0 0 5.25 21h6z"
                class="item"
              />
            </g>
          </svg>
          <p class="item-text" style="margin-left: 8px">{{ $t("gallery") }}</p>
        </label>
        <input
          id="create-post-file"
          type="file"
          @change="onFileChange"
          multiple="multiple"
          accept="image/*, video/*"
        />
      </div>
    </div>
  </div>
  <div class="createContentModalBackdrop" v-if="createContentModal">
    <div class="createContentModal">
      <div class="pseudoHeaderCreateContent">
        <p>Captura de imagens</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
          class="closeContentCreation"
          @click="this.turnOffCamera()"
        >
          <path
            d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"
            fill="#808080"
          />
        </svg>
      </div>


      <div class="displayCamera" id="displayCamera">
        <div class="recordingIndicator" v-if="this.recordingVideo">
          <div class="recordingCircle"></div>
          <p class="recordingTiming" id="timer">
            {{ recordMinutes }}:{{ recordSeconds }}
          </p>
        </div>
        <video id="video" width="530" height="398" autoplay></video>
        <canvas
          id="canvas"
          width="320"
          height="240"
          style="display: none"
        ></canvas>
      </div>
      <div  class="bubblesHolder"  >
        <div class="bubble bubblePhoto" @click="takePhoto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffff"
              d="M12 18q2.075 0 3.538-1.462Q17 15.075 17 13q0-2.075-1.462-3.538Q14.075 8 12 8Q9.925 8 8.463 9.462Q7 10.925 7 13q0 2.075 1.463 3.538Q9.925 18 12 18Zm0-2q-1.25 0-2.125-.875T9 13q0-1.25.875-2.125T12 10q1.25 0 2.125.875T15 13q0 1.25-.875 2.125T12 16Zm6-6q.425 0 .712-.288Q19 9.425 19 9t-.288-.713Q18.425 8 18 8t-.712.287Q17 8.575 17 9t.288.712Q17.575 10 18 10ZM4 21q-.825 0-1.412-.587Q2 19.825 2 19V7q0-.825.588-1.412Q3.175 5 4 5h3.15L8.7 3.325q.15-.15.337-.238Q9.225 3 9.425 3h5.15q.2 0 .388.087q.187.088.337.238L16.85 5H20q.825 0 1.413.588Q22 6.175 22 7v12q0 .825-.587 1.413Q20.825 21 20 21Z"
            />
          </svg>
        </div>
        <div class="bubble bubbleRecord" @click="recordVideo">
          <div class="innerBubbleRecord" v-if="this.recordingVideo == false">
          </div>
          <div class="innerBubbleRecordSquare" v-else>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";


import { Pagination, Navigation } from "swiper";


// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";


import EditoriaService from "@/services/editoria.service";


import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";


export default {
  name: "CreatePost",
  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Navigation],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },


  props: {
    userName: "",
    userImage: "",
    editorialId: "",
  },


  emits: ["getFeed", "uploading"],


  data() {
    return {
      stream: "",
      firstPhoto: true,
      recordingVideo: false,
      createContentModal: false,
      recordSeconds: 0,
      recordMinutes: 0,
      newPostInfo: {
        text: "",
        content: [],
      },
    };
  },


  unmounted() {
    this.$emit("uploading", this.editorialId, false);
  },


  methods: {
    createPost(editorialId) {
      if (this.newPostInfo.text == "" && this.newPostInfo.content.length == 0) {
        toast.warning(this.$t("warnings.noContentPost"));
      } else {
        toast.warning(this.$t("warnings.uploadingPost"));
        this.$emit("uploading", editorialId, true);
        EditoriaService.createPost(editorialId, this.newPostInfo).then(
          (response) => {
            if (!response.error) {
              //  console.log("opa",this.editorialId)
              this.$emit("getFeed", response.editorial_id);
              this.$emit("uploading", response.editorial_id, false);
              toast.success(this.$t("actions.successPostSend"));
            }
          }
        );
        this.newPostInfo.text = "";
        this.newPostInfo.content = [];
      }
    },


    updateFiles(event) {
      var selectedFile = event.target.files;
      for (let i = 0; i < selectedFile.length; i++) {
        this.setObjSrc(selectedFile[i]);
      }
    },


    onFileChange(e) {
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.newPostInfo.content.push(selectedFiles[i]);
      }


      for (let i = 0; i < this.newPostInfo.content.length; i++) {
        if (
          this.newPostInfo.content[i].type == "image/png" ||
          this.newPostInfo.content[i].type == "image/jpeg" ||
          this.newPostInfo.content[i].type == "image/gif"
        ) {
          let reader = new FileReader(); //instantiate a new file reader


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }


        if (this.newPostInfo.content[i].type == "video/mp4") {
          let reader = new FileReader();


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }
      }
    },


    deleteImageFromContent(index) {
      this.newPostInfo.content.splice(index, 1);


      for (let i = 0; i < this.newPostInfo.content.length; i++) {
        if (
          this.newPostInfo.content[i].type == "image/png" ||
          this.newPostInfo.content[i].type == "image/jpeg" ||
          this.newPostInfo.content[i].type == "image/gif"
        ) {
          let reader = new FileReader(); //instantiate a new file reader


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }


        if (this.newPostInfo.content[i].type == "video/mp4") {
          let reader = new FileReader();


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }
      }
      this.$forceUpdate();
    },


    async handleCamera() {
      // console.log("Ligou!")

      this.createContentModal = true;
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 1920, height: 1080 },
        audio: false,
      });

      let video = document.querySelector("#video");
      video.srcObject = this.stream;
    },


    turnOffCamera() {
      let video = document.querySelector("#video");


      this.createContentModal = false;


      this.stream.getTracks().forEach((track) => track.stop());


      video.srcObject = null;


      // console.log("Desligou!");
    },


    recordVideo() {
      // console.log("RECORDING VIDEO?", this.recordingVideo);
      if (this.recordingVideo) {
        this.recordingVideo = false;
        // console.log("Stop Video");
        this.$forceUpdate();
        this.startTimer(false);
        return;
      }
      this.recordingVideo = true;
      this.startTimer(true);
      this.$forceUpdate();
    },


    startTimer(entry) {
      if (entry) {
        this.recordSeconds += 1;


        if (this.recordSeconds == 60) {
          this.recordSeconds = 0;
          this.recordMinutes += 1;
        }


        // this.startTimer(true);
      }
    },


    takePhoto() {
      let canvas = document.querySelector("#canvas");
      let canvasContext = canvas.getContext("2d");
      if (this.firstPhoto) {
        canvasContext.translate(canvas.width, 0);
        canvasContext.scale(-1, 1);
        this.firstPhoto = false;
      }
      canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
      let dataurl = canvas.toDataURL("image/jpeg");
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);


      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }


      const filePhoto = new File([u8arr], "photo", { type: mime });


      this.newPostInfo.content.push(filePhoto);


      this.turnOffCamera();
      


      for (let i = 0; i < this.newPostInfo.content.length; i++) {
        if (
          this.newPostInfo.content[i].type == "image/png" ||
          this.newPostInfo.content[i].type == "image/jpeg" ||
          this.newPostInfo.content[i].type == "image/gif"
        ) {
          let reader = new FileReader(); //instantiate a new file reader


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }


        if (this.newPostInfo.content[i].type == "video/mp4") {
          let reader = new FileReader();


          reader.addEventListener(
            "load",
            function () {
              this.$refs["image" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          ); //add event listener


          reader.readAsDataURL(this.newPostInfo.content[i]);
        }
      }
    },
  },
};
</script>


<style scoped>
@keyframes blink {
  0% {
    opacity: 0;
  }


  50% {
    opacity: 1;
  }


  100% {
    opacity: 0;
  }
}


.recordingTiming {
  margin: auto;
  margin-top: -3px;
}


.recordingIndicator {
  position: absolute;
  right: 15px;
  top: 12px;
  display: flex;
  align-items: center;
  height: 18px;
  z-index: 12;
}


.recordingCircle {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-color: var(--red);
  animation: blink 1.5s linear infinite;
}


#video-preview {
  position: relative;
}


#video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: relative;
}


.createContentModalBackdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 999;
}


.createContentModal {
  background-color: var(--white);
  border-radius: 0.8rem;
  position: fixed; 
  top: 200px;
}


.displayCamera {
  margin: auto;
  background-color: var(--darker-grey);
  width: 530px;
  height: 398px;
  display: flex;
  position: relative;
}


.bubble {
  width: 50px;
  height: 50px;
  background-color: var(--yellow);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.bubbleRecord {
  background-color: var(--red);
}


.bubblePhoto {
  background-color: var(--yellow);
}


.bubblesHolder {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 10px;
}


.innerBubbleRecord {
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}


.innerBubbleRecordSquare {
  background-color: white;
  width: 20px;
  height: 20px;
}


.pseudoHeaderCreateContent {
  width: 543px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  font-weight: bold;
}


.closeContentCreation {
  margin-top: -2px;
  cursor: pointer;
}


.delete-image-preview {
  border-radius: 50%;
  background-color: var(--yellow);
  z-index: 12;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 6px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.post-swiper-createPost {
  height: 100%;
  display: block;
  padding-left: 0px;
  margin-bottom: 20px;
}


.post-swiper-slide-createPost {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin: auto;
}


.inner-image-holder {
  max-height: 470px;
  width: 95%;
  border-radius: 4px;
  max-height: 470px;
  margin-top: 25px;
  background-color: var(--background-grey);
  object-fit: cover;
  cursor: pointer;
}


.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction .swiper-pagination-bullet-active {
  background: var(--yellow);
}


.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}


.swiper,
.swiper-initialized,
.swiper-horizontal .swiper-pointer-events,
.swiper-backface-hidden {
  margin-left: 0;
}


.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 66px;
}


hr {
  width: 95%;
  margin: auto;
  margin-top: 18px;
}


#create-post-file {
  display: none;
}


.create-post-file {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.sendMsgBtn {
  position: absolute;
  right: 28px;
}


.svgSend {
  background: var(--yellow);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}


.newPostFileHolder {
  width: 604px;
  height: 450px;
  background-color: var(--background-grey);
  border-radius: 8px;
  margin: auto;
  margin-top: 15px;
}


.newPostFileHolderNoDisplay {
  width: 590px;
  height: 450px;
  background-color: var(--background-grey);
  border-radius: 8px;
  margin: auto;
  margin-top: 15px;
  display: none;
}


.right-button-icon {
  fill: #757575;
}


.userProfilePic {
  border-radius: 100%;
  width: 46px;
  height: 46px;
  object-fit: cover;
}


.createpost-container {
  margin: auto;
  display: block;
  background-color: white;
  border-radius: 5px;
  border: 2px solid rgba(229, 229, 229, 1);
  padding: 15px 0px;
  margin-bottom: 18px;
  width: 630px;
  position: relative;
}


.first-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 97%;
  height: 55%;
  margin: auto;
}


.item-text {
  margin-left: 8px;
  margin-top: 18px;
  font-size: 14px;
}


#input-text {
  background-color: var(--background-grey);
  border: none;
  border-radius: 8px;
  width: 95%;
  height: 40px;
  padding: 5px 15px 5px 15px;
  margin-left: 15px;
  margin-right: 15px;
}


.item {
  fill: var(--yellow);
}


#input-text:focus,
#input-text:focus {
  outline: none;
}


.second-row {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  height: 45%;
  margin-top: 15px;
}


.third-row-createPost {
  display: flex;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}


.item-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: -3px;
  cursor: pointer;
  height: 40px;
  border-left: 1px solid var(--ligth-grey);
}


#nav-user {
  border-radius: 50%;
  width: 50px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}


@media only screen and (max-width: 1260px) {
  .createpost-container {
    width: 528px;
    margin-left: 91px;
  }


  .post-container {
    width: 528px;
    margin-left: 91px;
  }
}
</style>



