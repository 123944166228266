import { ApiService } from "./index";
class AuthService {
  login(data) {
    if (data.type == "email") {
      return ApiService.post("/app/v5/auth/user/login", {
        email: data.userinfo,
        password: data.password,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    } else {
      return ApiService.post("/app/v5/auth/user/login", {
        numero_celular: `55${data.userinfo}`,
        password: data.password,
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  changeCompany(userId) {
    return ApiService.get(`/app/v3/auth/select/${userId}`).then(
      (response) => response.data
    );
  }
}
export default new AuthService();
