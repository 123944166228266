import { ApiService } from "./index";

class NotificationService {
  getNotifications(userId) {
    return ApiService.get(`/app/v1/push/${userId}`).then((data) => data.data);
  }
  getNewNotifications() {
    return ApiService.get(`/app/v1/user/notifys`).then((data) => data.data);
  }

  confirmNotifications(idPush) {
    return ApiService.post(`/app/v1/push/${idPush}`).then(
      (response) => response.data
    );
  }

  confirmationViewNotification(identifier_notify, status_notify ){
    const formdata = new FormData();
      formdata.append("identifier_notify", identifier_notify);
      formdata.append("status_notify", status_notify);

      return ApiService.post(
        `app/v1/user/consumer/notifys`, formdata
      ).then((data) => data);
  }

  confirmAllNotifications(userId) {
    return ApiService.post(`/app/v1/push/all/${userId}`).then(
      (response) => response.data
    );
  }
}
export default new NotificationService();
