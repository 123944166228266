<template>
  <div class="userFixed" id="userFixed " >
    <div class="userinfo-container">
      <div class="userInfo">
        <div style="background-color: var(--yellow); width: 100%; height: 60px"></div>
        <div @click="editProfile">
          <div class="NonUserProfilePic" v-if="this.userImage == undefined || this.userImage == ''">
            <svg width="48" height="48" viewBox="0 0 24 24" class="left-icon">
              <path fill="#757575"
                d="M17.754 14a2.249 2.249 0 0 1 2.249 2.25v.918a2.75 2.75 0 0 1-.513 1.598c-1.545 2.164-4.07 3.235-7.49 3.235c-3.421 0-5.944-1.072-7.486-3.236a2.75 2.75 0 0 1-.51-1.596v-.92A2.249 2.249 0 0 1 6.251 14h11.502ZM12 2.005a5 5 0 1 1 0 10a5 5 0 0 1 0-10Z" />
            </svg>
          </div>
          <img :src="this.userImage" class="userProfilePic" v-else />
        </div>
        <p class="username-text">{{ fullName }}</p>
        <p class="username-role">{{ userRole }}</p>
      </div>
      <div @click="goRoute('/')" :class="routeName == 'home'
        ? 'selected-shortcut'
        : routeName == 'post'
          ? 'selected-shortcut'
          : 'shortcut'
        ">
        <div :class="routeName == 'home'
          ? 'selected-svg-div'
          : routeName == 'post'
            ? 'selected-svg-div'
            : 'svg-div'
          ">
          
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" >
            <path fill="#f8b82b"
              d="M10.55 2.533a2.25 2.25 0 0 1 2.9 0l6.75 5.695c.508.427.8 1.056.8 1.72v9.802a1.75 1.75 0 0 1-1.75 1.75h-3a1.75 1.75 0 0 1-1.75-1.75v-5a.75.75 0 0 0-.75-.75h-3.5a.75.75 0 0 0-.75.75v5a1.75 1.75 0 0 1-1.75 1.75h-3A1.75 1.75 0 0 1 3 19.75V9.947c0-.663.292-1.292.8-1.72l6.75-5.694Z" />
          </svg>
        </div>
        <p :class="routeName == 'home'
          ? 'selected-shortcut-text'
          : routeName == 'post'
            ? 'selected-shortcut-text'
            : 'shortcut-text'
          ">
          {{ $t("home") }}
        </p>
      </div>

      <div class="shortcut" @click="goRoute('/communication')" :class="routeName == 'communication'
        ? 'selected-shortcut'
        : routeName == 'ticket'
          ? 'selected-shortcut'
          : 'shortcut'
        " v-if="this.communication.status">
        <div :class="routeName == 'communication'
          ? 'selected-svg-div'
          : routeName == 'ticket'
            ? 'selected-svg-div'
            : 'svg-div'
          ">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path fill="#f8b82b"
              d="M14.704 3.442c.191.226.296.512.296.808v15.502a1.25 1.25 0 0 1-2.058.954L7.975 16.5H4.25A2.25 2.25 0 0 1 2 14.25v-4.5A2.25 2.25 0 0 1 4.25 7.5h3.725l4.968-4.204a1.25 1.25 0 0 1 1.761.147Zm2.4 5.198a.75.75 0 0 1 1.03.25c.574.94.862 1.992.862 3.14c0 1.149-.288 2.201-.862 3.141a.75.75 0 1 1-1.28-.781c.428-.702.642-1.483.642-2.36c0-.876-.214-1.657-.642-2.359a.75.75 0 0 1 .25-1.03Z" />
          </svg>
        </div>
        <p :class="routeName == 'communication'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          " v-if="this.communication.name.length != 0">
          {{ this.communication.name }}
        </p>
        <p :class="routeName == 'communication'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          " v-else>
          {{ $t("communication") }}
        </p>
      </div>

      <div class="shortcut" @click="goRoute('/ombudsman')"
        :class="routeName == 'ombudsman' ? 'selected-shortcut' : 'shortcut'" v-if="this.ombudsman.status">
        <div :class="routeName == 'ombudsman' ? 'selected-svg-div' : 'svg-div'">
         

          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256" >
            <path fill="#f8b82b"
              d="M132 24A100.11 100.11 0 0 0 32 124v84.33A15.69 15.69 0 0 0 47.67 224H132a100 100 0 0 0 0-200ZM88 140a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm44 0a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm44 0a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z" />
          </svg>
        </div>

        <p :class="routeName == 'ombudsman'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          " v-if="this.ombudsman.name.length != 0">
          {{ this.ombudsman.name }}
        </p>
        <p :class="routeName == 'ombudsman'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          " v-else>
          {{ $t("ombudsman") }}
        </p>
      </div>

      <div class="shortcut" @click="goRoute('/birthdayUser')"
        :class="routeName == 'birthdayUser' ? 'selected-shortcut' : 'shortcut'">
        <div :class="routeName == 'birthdayUser' ? 'selected-svg-div' : 'svg-div'">
          <img src="/assets/birthday/iconeBirthday.png" style="width: 33px; height: 33px" />
        </div>

        <p :class="routeName == 'birthdayUser'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          "></p>
        <p :class="routeName == 'birthdayUser'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          ">
          {{ $t("Aniversariantes") }}
        </p>
      </div>

      <div
        class="shortcut"
        @click="goRoute('/appCloud')"
        :class="routeName == 'appCloud' ? 'selected-shortcut' : 'shortcut'"
      >
        <div
          :class="routeName == 'appCloud' ? 'selected-svg-div' : 'svg-div'"
        >
          <img
            src="/assets/birthday/appcloud.svg"
            style="width: 33px; height: 33px; color:#FAB900; "
          />
        </div>

        <p :class="routeName == 'appCloud'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          "></p>
        <p :class="routeName == 'appCloud'
          ? 'selected-shortcut-text'
          : 'shortcut-text'
          ">
          {{ $t("App Cloud") }}
        </p>
      </div>
      <div class="shortcut" @click="goRoute('/complaints')" :class="routeName == 'complaints'
        ? 'selected-shortcut'
        : routeName == 'complaints'
          ? 'selected-shortcut'
          : 'shortcut'
        ">
        <div :class="routeName == 'complaints'
          ? 'selected-svg-div'
          : routeName == 'complaints'
            ? 'selected-svg-div'
            : 'svg-div'
          ">
          <img src="/assets/main/Erro.png" style="width: 30px; heigth: 30px" />
        </div>
        <p :class="routeName == 'complaints'
          ? 'selected-shortcut-text'
          : routeName == 'complaints'
            ? 'selected-shortcut-text'
            : 'shortcut-text'
          ">
          {{ $t("complaints") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OmbudsmanService from "@/services/ombudsman.service";
import communicationService from "@/services/communication.service";
import UserService from "@/services/user.service";

export default {
  name: "Userinfo",
  props: {
    userRole: "",
    fullName: "",
    routeName: "",
    representativeImg: "",
    allRepresentatives: "",
    userRank: "",
  },

  data() {
    return {
      userImage: "",
    };
  },

  beforeMount() {
    this.getOmbudsman();
    this.getCommunication();
    UserService.getOwnImage().then((data) => {
      
      if (!data.error) {
        this.userImage = "data:image/png;base64," + data;
        this.$forceUpdate();
      }
    });
  },

  data() {
    return {
      ombudsman: {
        name: "",
        status: "",
      },

      communication: {
        name: "",
        status: "",
      },
    };
  },

  methods: {
    goRoute(path) {
      this.$router.push(path);

      if (path == this.$router.currentRoute._value.fullPath) {
        window.location.reload();
      }
    },

    editProfile() {
      this.$emit("openProfileModal");
    },

    getOmbudsman() {
      OmbudsmanService.getConfig().then((data) => {
        if (data.data != null) {
          this.ombudsman.name = data.data.titulo;
          this.ombudsman.status = data.data.status;
        } else {
          this.ombudsman.name = this.$root.$t("ombudsman");
          this.ombudsman.status = false;
        }
      });
    },

    getCommunication() {
      communicationService.getConfig().then((data) => {
        if (data.data != null) {
          this.communication.name = data.data.nome;
          this.communication.status = data.data.status;
        } else {
          this.communication.name = this.$root.$t("communication");
          this.communication.status = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.userFixed {
  overflow-x: hidden;
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 280px;
  z-index: 999;
  box-shadow: 2px 0px 2px -2px rgba(0, 0, 0, 0.2);
}

.userInfo {
  width: 100%;
  text-align: center;
}

.shortcut {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 6px;
}

.selected-shortcut {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  width: 100%;
  border-right: 3px solid var(--yellow);
  margin-top: 5px;
  margin-bottom: 6px;
  background-color: rgba(250, 185, 0, 0.1);
}

.shortcut-text {
  color: rgba(102, 102, 102, 1);
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 8px;
  margin-top: 18px;
}

.selected-shortcut-text {
  color: rgba(250, 185, 0, 1);
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0px;
  margin-left: 8px;
  margin-top: 18px;
}

.svg-div {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.selected-svg-div {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}

.NonUserProfilePic {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  margin: auto;
  margin-top: -40px;
  background: var(--background-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.userProfilePic {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin: auto;
  margin-top: -50px;
  cursor: pointer;
  object-fit: cover;
}

.username-text {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 2px;
  font-weight: bold;

  width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.username-role {
  color: rgba(22, 31, 61, 1);
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 16px;
}

#nav-user {
  border-radius: 100%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.representativeImg {
  width: 220px;
  height: 46px;
  margin: auto;
}

.image-holder {
  display: flex;
}

@media only screen and (min-width: 1350px) {
  .userFixed {
    width: 280px;
  }
}
</style>
