import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "@/store/auth.js";
import OmbudsmanService from "@/services/ombudsman.service";
import { AES, enc } from "crypto-js";
const SECRET_KEY = "zbTtuYawpT6htlizS8GmjH8KSizcItPy";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      props: true,
      components: {
        default: () => import("../views/page/editorial/Home.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        AppCloud: () => import("@/components/Appcloud.vue"),
      },
      alias: ["/home"],
      meta: {
        auth: true,
      },
    },
    {
      path: "/communication",
      name: "communication",
      components: {
        default: () =>
          import("../views/page/comunicationChanel/Communication.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        Channels: () => import("@/components/comunication/Channels.vue"),
      },
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/page/comunicationChanel/Communication.vue"
        ),
    },
    {
      path: "/ombudsman",
      name: "ombudsman",
      components: {
        default: () => import("../views/Ombudsman.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
      },
      meta: {
        auth: true,
        ombudsman: true,
      },

      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/Ombudsman.vue"
        ),
    },
    
    {
      path: "/post/:editorialId/:feedId",
      name: "post",
      components: {
        default: () => import("../views/page/editorial/OnePost.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
      },
    },
    {
      path: "/birthdayUser",
      name: "birthdayUser",
      components: {
        default: () => import("../views/page/birthdayUser/birthdayUser.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        Channels: () => import("@/components/comunication/Channels.vue"),
      },
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/page/birthdayUser/birthdayUser.vue"
        ),
    },

    {
      path: "/appCloud",
      name: "appCloud",
      components: {
        default: () => import("../views/page/appCloud/appCloud.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        Channels: () => import("@/components/comunication/Channels.vue"),
      },
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/page/appCloud/appCloud.vue"
        ),
    },


    {
      path: "/notification",
      name: "notification",
      components: {
        default: () => import("../views/page/notification/notification.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        Channels: () => import("@/components/comunication/Channels.vue"),
      },
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/page/birthdayUser/birthdayUser.vue"
        ),
    },
    {
      path: "/complaints",
      name: "complaints",
      components: {
        default: () => import("../views/page/complaints/complaints.vue"),
        NavBar: () => import("@/components/Navbar.vue"),
        UserInfo: () => import("@/components/user/UserInfo.vue"),
        Channels: () => import("@/components/comunication/Channels.vue"),
      },
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "communication" */ "../views/page/complaints/complaints.vue"
        ),
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "Bug" */ "../views/page/login/Login.vue"),
      meta: {
        title: "AppTalk - Login",
        public: true,
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      components: {
        default: () => import("../views/page/PageNotFound/PageNotFound.vue"),
      },
      component: () =>
        import(
          /* webpackChunkName: "notFound" */ "../views/page/PageNotFound/PageNotFound.vue"
        ),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const auth = useAuth();
  if (to.meta?.auth) {
    if (auth.token) {
      const isAuthenticated = auth.verifyToken();
      if (isAuthenticated) {
        if (to.meta?.ombudsman) {
          OmbudsmanService.getConfig().then((data) => {
            let ombudsmanStatus = data.data.status;

            ombudsmanStatus = ombudsmanStatus == 0 ? false : true;

            if (ombudsmanStatus) {
              next();
            } else {
              next({ name: "home" });
            }
          });
        } else {
          next();
        }
      }
    } else {
      if (to.query != undefined && to.query != null) {
        router.push({ path: '/login', query: { q: to.query.v } })
      } else {
        next({ path: "/login" });
      }
    }
  } else {
    if (to.path == "/login" && auth.logged == "true") {
      next(from);
    } else {
      next();
    }
  }
});

export default router;
