import { ApiService } from "./index";

class OmbudsmanService {
  getArquivados(channelId) {
    return ApiService.get(`/app/v1/report/${channelId}/records`).then(
      (data) => data.data
    );
  }

  getConfig() {
    return ApiService.get("/app/v1/report/get/config").then(
      (data) => data.data
    );
  }

  getChannelById(id) {
    return ApiService.get(`/app/v1/report/${id}`).then((data) => data.data);
  }

  getUser() {
    return ApiService.get("/app/v1/report/channels/user").then(
      (data) => data.data
    );
  }

  getTermos(id) {
    return ApiService.get(`/app/v1/report/${id}/files`).then(
      (data) => data.data
    );
  }

  getTermosStatus(id) {
    return ApiService.get(`/app/v1/report/${id}/files/verify`).then(
      (data) => data.data
    );
  }

  acceptTermo(fileId, channelId) {
    return ApiService.post(
      `/app/v1/report/${channelId}/files/${fileId}/accept`
    ).then((data) => data.data);
  }

  getVideos(idCanal) {
    return ApiService.get(`/app/v1/report/${idCanal}/videos`).then(
      (data) => data.data
    );
  }

  getVideoById(videoId) {
    return ApiService.get(`/app/v1/report/videos/${videoId}`).then(
      (data) => data.data
    );
  }

  getQuestions(canalId) {
    return ApiService.get(`/app/v1/report/${canalId}/questions`).then(
      (data) => data.data
    );
  }

  createComplaint(channelId, data) {
    const formdata = new FormData();

    formdata.append("id_usuario", data.identification ? 1 : 0);
    formdata.append("questionario_resposta", data.questions);

    formdata.append("denuncia", data.message);

    return ApiService.post(`/app/v1/report/${channelId}/store`, formdata).then(
      (data) => data.data
    );
  }
  getTermoChannel(channelId, typeDoc) {
    return ApiService.get(`/app/v1/report/${channelId}/${typeDoc}/archive`);
  }
  sendFile(token, file, idCanal) {
    const formdata = new FormData();

    formdata.append("uniqid", token);
    formdata.append("file", file);

    return ApiService.post(
      `/app/v1/report/${idCanal}/store/files`,
      formdata
    ).then((response) => response.data);
  }

  getAdm() {
    return ApiService.get("/app/v1/report/channels/admin").then(
      (data) => data.data
    );
  }

  createParecer(idReport, message) {
    const formdata = new FormData();

    formdata.append("texto", message);

    return ApiService.post(
      `/app/v1/report/${idReport}/observer`,
      formdata
    ).then((response) => response.data);
  }

  getParecer(idReport) {
    return ApiService.get(`/app/v1/report/${idReport}/comments`).then(
      (data) => data.data
    );
  }

  getComplaints(channelId) {
    return ApiService.get(`/app/v1/report/channels/${channelId}/admin`).then(
      (data) => data.data
    );
  }

  getComplaintById(complaintId) {
    return ApiService.get(`/app/v1/report/${complaintId}/admin`).then(
      (data) => data.data
    );
  }

  updateComplaintStatus(complaintId, statusId) {
    const formdata = new FormData();

    formdata.append("status_andamento", statusId);

    return ApiService.post(
      `/app/v1/report/${complaintId}/add/status`,
      formdata
    ).then((response) => response.data);
  }

  finalizarComplaint(complaintId) {
    return ApiService.post(`/app/v1/report/${complaintId}/close`).then(
      (response) => response.data
    );
  }

  sendMessageToFinish(complaintId, message) {
    const formdata = new FormData();

    formdata.append("texto", message);

    return ApiService.post(
      `/app/v1/report/${complaintId}/observer`,
      formdata
    ).then((response) => response.data);
  }
}

export default new OmbudsmanService();
